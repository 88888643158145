import { useMutation, useQuery, useQueryClient } from "react-query";
import { RoomTypeFacilities } from "../interfaces/room-type-facilities";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useRoomTypeFacilitiesList = (param?: Partial<RoomTypeFacilities>) => {
    const client = useHttp();
    const result = useQuery<any>(['roomTypeFacilitiesPage', param], () => client('roomTypeFacilities', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useRoomTypeFacilitiesListAll = () => {
    const client = useHttp();
    const result = useQuery<any>(['roomTypeFacilitiesListAll'], () => client('roomTypeFacilities/listAll'))
    const data = result.data?.data;
    return { ...result, data };
}

export const useEditRoomTypeFacilities = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<RoomTypeFacilities>) => client(`roomTypeFacilities`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('roomTypeFacilitiesPage')
    }
    )
}

export const useAddRoomTypeFacilities = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<RoomTypeFacilities>) => client(`roomTypeFacilities`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('roomTypeFacilitiesPage')
    }
    )
}

export const useDeleteRoomTypeFacilities = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ roomTypeFacilitiesId }: { roomTypeFacilitiesId: number }) =>
            client(`roomTypeFacilities/${roomTypeFacilitiesId}`, {
                method: "DELETE",
            }), {
        onSuccess: () => queryClient.invalidateQueries('roomTypeFacilitiesPage')
    }
    );
};

export const useRoomTypeFacilities = (roomTypeFacilitiesId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['roomTypeFacilities', { roomTypeFacilitiesId }],
        () => client(`roomTypeFacilities/${roomTypeFacilitiesId}`),
        {
            enabled: Boolean(roomTypeFacilitiesId)
        }
    )
}