import { Layout } from "antd";
import { SiderLayout } from "../../layouts/sider";
import { HeaderLayout } from "../../layouts/header";
import { FooterLayout } from "../../layouts/footer";
import { DashboardPage } from "../dashboard/dashboard";
import { StoreUpdatePage } from "../store/update";
import { StoreAdvListPage } from "../store-adv/list";
import { StoreAdvAddPage } from "../store-adv/add";
import { StoreAdvUpdatePage } from "../store-adv/update";
import { StoreFloorListPage } from '../store-floor/list';
import { StoreFloorAddPage } from '../store-floor/add';
import { StoreFloorUpdatePage } from '../store-floor/update';
import { StoreFloorGoodsListPage } from '../store-floor-goods/list';
import { StoreFloorGoodsListNoPage } from '../store-floor-goods/list-no';
import { StorePhotoListPage } from "../store-photo/list";
import { StorePhotoAddPage } from "../store-photo/add";
import { StorePhotoUpdatePage } from "../store-photo/update";
import { StorePhotoAlbumListPage } from "../store-photo-album/list";
import { StorePhotoAlbumAddPage } from "../store-photo-album/add";
import { StorePhotoAlbumUpdatePage } from "../store-photo-album/update";
import { StoreVideoListPage } from "../store-video/list";
import { StoreVideoAddPage } from "../store-video/add";
import { StoreVideoUpdatePage } from "../store-video/update";
import { StoreVideoAlbumListPage } from "../store-video-album/list";
import { StoreVideoAlbumAddPage } from "../store-video-album/add";
import { StoreVideoAlbumUpdatePage } from "../store-video-album/update";
import { GoodsListPage } from "../goods/list";
import { GoodsAddPage } from "../goods/add";
import { GoodsUpdatePage } from "../goods/update";
import { OrdersListPage } from "../orders/list";
import { OrdersDetailPage } from "../orders/detail";
import { OrdersVerificationPage } from "../orders/verification";
import { OrdersRefundListPage } from "../orders-refund/list";
import { OrdersRefundDetailPage } from "../orders-refund/detail";
import { StoreBillListPage } from "../store-bill/list";
import { StoreBillDetailListPage } from "../store-bill-detail/list";
import { StoreMoneyLogListPage } from "../store-money-log/list";
import { StoreWithdrawCashListPage } from "../store-withdraw-cash/list";
import { StoreWithdrawCashAddPage } from "../store-withdraw-cash/add";
import { StoreSettleAccountListPage } from "../store-settle-account/list";
import { StoreSettleAccountAddPage } from "../store-settle-account/add";
import { StoreSettleAccountUpdatePage } from "../store-settle-account/update";
import { StoreAccountListPage } from "../store-account/list";
import { StoreAccountAddPage } from "../store-account/add";
import { StoreAccountUpdatePage } from "../store-account/update";
import { StoreGoodsClassListPage } from "../store-goods-class/list";
import { StoreGoodsClassAddPage } from "../store-goods-class/add";
import { StoreGoodsClassUpdatePage } from "../store-goods-class/update";
import { GoodsSpecListPage } from "../goods-spec/list";
import { GoodsSpecAddPage } from "../goods-spec/add";
import { GoodsSpecUpdatePage } from "../goods-spec/update";
import { GoodsSpecValueListPage } from "../goods-spec-value/list";
import { GoodsSpecValueAddPage } from "../goods-spec-value/add";
import { GoodsSpecValueUpdatePage } from "../goods-spec-value/update";
import { GoodsRecommedListPage } from "../goods/listRecommed";
import { Route, Routes, Navigate } from "react-router";
import { HashRouter as Router } from "react-router-dom";
import { StoreFacilitiesListPage } from "../store-facilities/list";
import { StoreFacilitiesAddPage } from "../store-facilities/add";
import { StoreFacilitiesUpdatePage } from "../store-facilities/update";

//房型分类
import { RoomClassListPage } from "../room-class/list";
import { RoomClassAddPage } from "../room-class/add";
import { RoomClassUpdatePage } from "../room-class/update";
//房型
import { RoomTypeListPage } from "../room-type/list";
import { RoomTypeAddPage } from "../room-type/add";
import { RoomTypeUpdatePage } from "../room-type/update";
//房型标签
import { RoomTypeTagListPage } from "../room-type-tag/list";
import { RoomTypeTagAddPage } from "../room-type-tag/add";
import { RoomTypeTagUpdatePage } from "../room-type-tag/update";
//房型与房型标签
import { RoomTypeRoomTypeTagListPage } from "../room-type-room-type-tag/list";
import { RoomTypeRoomTypeTagAddPage } from "../room-type-room-type-tag/add";
import { RoomTypeRoomTypeTagUpdatePage } from "../room-type-room-type-tag/update";
//房型与房型设施
import { RoomTypeRoomTypeFacilitiesListPage } from "../room-type-room-type-facilities/list";
import { RoomTypeRoomTypeFacilitiesAddPage } from "../room-type-room-type-facilities/add";
import { RoomTypeRoomTypeFacilitiesUpdatePage } from "../room-type-room-type-facilities/update";
//房型设施
import { RoomTypeFacilitiesListPage } from "../room-type-facilities/list";
import { RoomTypeFacilitiesAddPage } from "../room-type-facilities/add";
import { RoomTypeFacilitiesUpdatePage } from "../room-type-facilities/update";
//房型详情
import { RoomTypeDetailListPage } from "../room-type-detail/list";
import { RoomTypeDetailAddPage } from "../room-type-detail/add";
import { RoomTypeDetailUpdatePage } from "../room-type-detail/update";
//房型分类
import { RoomTypeClassListPage } from "../room-type-class/list";
import { RoomTypeClassAddPage } from "../room-type-class/add";
import { RoomTypeClassUpdatePage } from "../room-type-class/update";

const { Content } = Layout;

export const Authenticated = () => {
  return (
    <Layout>
      <Router>
        <SiderLayout />
        <Layout className="site-layout">
          <HeaderLayout />
          <Content
            style={{
              margin: "24px 16px 0",
              padding: 24,
              minHeight: "calc(100vh - 150px)",
              overflow: "initial",
              background: "#fff",
            }}
          >
            <Routes>
              <Route path={"/dashboard"} element={<DashboardPage />} />
              <Route path={"/store/info"} element={<StoreUpdatePage />} />
              <Route path={"/store/adv/list"} element={<StoreAdvListPage />} />
              <Route path={"/store/adv/add"} element={<StoreAdvAddPage />} />
              <Route path={"/store/adv/update/:advId"}  element={<StoreAdvUpdatePage />} />
              <Route path={"/store/floor/list"} element={<StoreFloorListPage />} />
              <Route path={"/store/floor/add"} element={<StoreFloorAddPage />} />
              <Route path={"/store/floor/update/:storeFloorId"} element={<StoreFloorUpdatePage />} />
              <Route path={"/store/floor/goods/list/:storeFloorId"} element={<StoreFloorGoodsListPage />} />
              <Route path={"/store/floor/goods/listNo/:storeFloorId"} element={<StoreFloorGoodsListNoPage />} />
              <Route path={"/store/photo/list"} element={<StorePhotoListPage />} />
              <Route path={"/store/photo/add"} element={<StorePhotoAddPage />} />
              <Route path={"/store/photo/update/:storePhotoId"} element={<StorePhotoUpdatePage />} />
              <Route path={"/store/photo/album/list"} element={<StorePhotoAlbumListPage />} />
              <Route path={"/store/photo/album/add"} element={<StorePhotoAlbumAddPage />} />
              <Route path={"/store/photo/album/update/:storePhotoAlbumId"} element={<StorePhotoAlbumUpdatePage />} />
              <Route path={"/store/video/list"} element={<StoreVideoListPage />} />
              <Route path={"/store/video/add"} element={<StoreVideoAddPage />} />
              <Route path={"/store/video/update/:storeVideoId"} element={<StoreVideoUpdatePage />} />
              <Route path={"/store/video/album/list"}  element={<StoreVideoAlbumListPage />} />
              <Route path={"/store/video/album/add"} element={<StoreVideoAlbumAddPage />}  />
              <Route path={"/store/video/album/update/:storeVideoAlbumId"} element={<StoreVideoAlbumUpdatePage />} />
              
              <Route
                path={"/store/facilities/list"}
                element={<StoreFacilitiesListPage />}
              />
              <Route
                path={"/store/facilities/add"}
                element={<StoreFacilitiesAddPage />}
              />
              <Route
                path={"/store/facilities/update/:storeFacilitiesId"}
                element={<StoreFacilitiesUpdatePage />}
              />
              
              <Route
                path={"/goods/recommed/list"}
                element={<GoodsRecommedListPage />}
              />

              <Route
                path={"/goods/class/list"}
                element={<StoreGoodsClassListPage />}
              />
              <Route
                path={"/goods/class/add"}
                element={<StoreGoodsClassAddPage />}
              />
              <Route
                path={"/goods/class/update/:storeGoodsClassId"}
                element={<StoreGoodsClassUpdatePage />}
              />

              <Route path={"/goods/manage/list"} element={<GoodsListPage />} />
              <Route path={"/goods/manage/add"} element={<GoodsAddPage />} />
              <Route
                path={"/goods/manage/update/:goodsId"}
                element={<GoodsUpdatePage />}
              />
              <Route
                path={"/goods/spec/list"}
                element={<GoodsSpecListPage />}
              />
              <Route path={"/goods/spec/add"} element={<GoodsSpecAddPage />} />
              <Route
                path={"/goods/spec/update/:goodsSpecId"}
                element={<GoodsSpecUpdatePage />}
              />
              <Route
                path={"/goods/spec/value/list/:goodsSpecId"}
                element={<GoodsSpecValueListPage />}
              />
              <Route
                path={"/goods/spec/value/add/:goodsSpecId"}
                element={<GoodsSpecValueAddPage />}
              />
              <Route
                path={"/goods/spec/value/update/:goodsSpecValueId"}
                element={<GoodsSpecValueUpdatePage />}
              />
              <Route
                path={"/orders/manage/list"}
                element={<OrdersListPage />}
              />
              <Route
                path={"/orders/manage/detail/:ordersId"}
                element={<OrdersDetailPage />}
              />
              <Route
                path={"/orders/verification"}
                element={<OrdersVerificationPage />}
              />
              <Route
                path={"/orders/refund/list"}
                element={<OrdersRefundListPage />}
              />
              <Route
                path={"/orders/refund/detail/:ordersRefundId"}
                element={<OrdersRefundDetailPage />}
              />
              <Route path={"/asset/storeBill/list"} element={<StoreBillListPage />} />
              <Route path={"/asset/storeBillDetail/list"} element={<StoreBillDetailListPage />} />
              <Route path={"/asset/storeMoneyLog/list"} element={<StoreMoneyLogListPage />} />
              <Route
                path={"/asset/storeWithdrawCash/list"}
                element={<StoreWithdrawCashListPage />}
              />
              <Route
                path={"/asset/storeWithdrawCash/add"}
                element={<StoreWithdrawCashAddPage />}
              />
              <Route
                path={"/asset/storeWithdrawCash/storeSettleAccount/list"}
                element={<StoreSettleAccountListPage />}
              />
              <Route
                path={"/asset/storeWithdrawCash/storeSettleAccount/add"}
                element={<StoreSettleAccountAddPage />}
              />
              <Route
                path={"/asset/storeWithdrawCash/storeSettleAccount/update/:storeSettleAccountId"}
                element={<StoreSettleAccountUpdatePage />}
              />
              <Route path={"/setting/storeAccount/list"} element={<StoreAccountListPage />} />
              <Route path={"/setting/storeAccount/add"} element={<StoreAccountAddPage />} />
              <Route
                path={"/setting/change/pwd"}
                element={<StoreAccountUpdatePage />}
              />

              
              {/* 房型分类 */}
              <Route path={"/goods/room-manage/room-type-class/list"} element={<RoomTypeClassListPage />} />
              <Route path={"/goods/room-manage/room-type-class/add"} element={<RoomTypeClassAddPage />} />
              <Route path={"/goods/room-manage/room-type-class/update/:roomTypeClassId"} element={<RoomTypeClassUpdatePage />} />
              {/* 房型详情 */}
              <Route path={"/goods/room-manage/room-type-detail/list"} element={<RoomTypeDetailListPage />} />
              <Route path={"/goods/room-manage/room-type-detail/add"} element={<RoomTypeDetailAddPage />} />
              <Route path={"/goods/room-manage/room-type-detail/update/:roomTypeDetailId"} element={<RoomTypeDetailUpdatePage />} />
              {/* 房型设施 */}
              <Route path={"/goods/room-manage/room-type-facilities/list"} element={<RoomTypeFacilitiesListPage />} />
              <Route path={"/goods/room-manage/room-type-facilities/add"} element={<RoomTypeFacilitiesAddPage />} />
              <Route path={"/goods/room-manage/room-type-facilities/update/:roomTypeFacilitiesId"} element={<RoomTypeFacilitiesUpdatePage />} />
              {/* 房型与房型设施 */}
              <Route path={"/goods/room-manage/room-type-room-type-facilities/list"} element={<RoomTypeRoomTypeFacilitiesListPage />} />
              <Route path={"/goods/room-manage/room-type-room-type-facilities/add"} element={<RoomTypeRoomTypeFacilitiesAddPage />} />
              <Route path={"/goods/room-manage/room-type-room-type-facilities/update/:roomTypeRoomTypeFacilitiesId"} element={<RoomTypeRoomTypeFacilitiesUpdatePage />} />
              {/* 房型与房型标签 */}
              <Route path={"/goods/room-manage/room-type-room-type-tag/list"} element={<RoomTypeRoomTypeTagListPage />} />
              <Route path={"/goods/room-manage/room-type-room-type-tag/add"} element={<RoomTypeRoomTypeTagAddPage />} />
              <Route path={"/goods/room-manage/room-type-room-type-tag/update/:roomTypeRoomTypeTagId"} element={<RoomTypeRoomTypeTagUpdatePage />} />
              {/* 房型标签 */}
              <Route path={"/goods/room-manage/room-type-tag/list"} element={<RoomTypeTagListPage />} />
              <Route path={"/goods/room-manage/room-type-tag/add"} element={<RoomTypeTagAddPage />} />
              <Route path={"/goods/room-manage/room-type-tag/update/:roomTypeTagId"} element={<RoomTypeTagUpdatePage />} />
              {/* 房型 */}
              <Route path={"/goods/room-manage/room-type/list"} element={<RoomTypeListPage />} />
              <Route path={"/goods/room-manage/room-type/add"} element={<RoomTypeAddPage />} />
              <Route path={"/goods/room-manage/room-type/update/:roomTypeId"} element={<RoomTypeUpdatePage />} />
              {/* 房型分类 */}
              <Route path={"/goods/room-manage/room-class/list"} element={<RoomClassListPage />} />
              <Route path={"/goods/room-manage/room-class/add"} element={<RoomClassAddPage />} />
              <Route path={"/goods/room-manage/room-class/update/:roomClassId"} element={<RoomClassUpdatePage />} />

              <Route index element={<Navigate to={"/dashboard"} />}></Route>
            </Routes>
          </Content>
          <FooterLayout />
        </Layout>
      </Router>
    </Layout>
  );
};
