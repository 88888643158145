import { useMutation, useQuery, useQueryClient } from "react-query";
import { RoomTypeRoomTypeTag } from "../interfaces/room-type-room-type-tag";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useRoomTypeRoomTypeTagList = (param?: Partial<RoomTypeRoomTypeTag>) => {
    const client = useHttp();
    const result = useQuery<any>(['roomTypeRoomTypeTagPage', param], () => client('roomTypeRoomTypeTag', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditRoomTypeRoomTypeTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<RoomTypeRoomTypeTag>) => client(`roomTypeRoomTypeTag`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('roomTypeRoomTypeTagPage')
        }
    )
}

export const useAddRoomTypeRoomTypeTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<RoomTypeRoomTypeTag>) => client(`roomTypeRoomTypeTag`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('roomTypeRoomTypeTagPage')
        }
    )
}

export const useDeleteRoomTypeRoomTypeTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ roomTypeRoomTypeTagId }: { roomTypeRoomTypeTagId: number}) =>
            client(`roomTypeRoomTypeTag/${roomTypeRoomTypeTagId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('roomTypeRoomTypeTagPage')
        }
    );
};

export const useRoomTypeRoomTypeTag = (roomTypeRoomTypeTagId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['roomTypeRoomTypeTag', {roomTypeRoomTypeTagId}],
        () => client(`roomTypeRoomTypeTag/${roomTypeRoomTypeTagId}`),
        {
            enabled: Boolean(roomTypeRoomTypeTagId)
        }
    )
}