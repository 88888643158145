import { Button, Col, message, Modal, Row, Space, Table, Image, Card, Form, Input, Select, Tag } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { ErrorBox } from "../../components/lib"
import { useRoomTypeList, useDeleteRoomType } from "../../services/room-type-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useRoomTypeSearchParams } from "./util"
import { getShopId } from "../../utils/auth-provider"
import { useAreaListAll } from "../../services/area-service"
import { useRoomTypeClassListAll } from "../../services/room-type-class-service"

export const RoomTypeListPage = () => {
    const shopId = getShopId();
    useDocumentTitle('房型列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const [param, setParam] = useRoomTypeSearchParams()
    const { isLoading, error, totalElements, data: list } = useRoomTypeList(useDebounce(param, 200))

    //分页
    const paginationProps = {
        showSizeChanger: true,
        onShowSizeChange: (current: number, pageSize: number) => {
            setParam({
                ...param,
                pageNum: current - 1,
                pageSize,
            });
        },
        defaultCurrent: 1,
        pageSize: param.pageSize,
        total: totalElements,
        onChange: (current: number, pageSize: number) =>
            setParam({
                ...param,
                pageNum: current - 1,
                pageSize,
            }),
    };
    //搜索
    const onFinish = (values: any) => {
        setParam({
            ...param,
            ...values,
            pageNum: null,
        });
    };
    //删除事件
    const { mutateAsync } = useDeleteRoomType();
    const confirmDeleteRoomType = (roomTypeId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ roomTypeId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    //准备区域列表
    const { data: areaListAll } = useAreaListAll();

    const { data: roomTypeClassListAll } = useRoomTypeClassListAll();
    const roomTypeClassListOptions = roomTypeClassListAll?.map((item: any) => {
        return (<Select.Option value={item.roomTypeClassId}>{item.roomTypeClassName}</Select.Option>)
    })
    return (
        <div>
            <Row style={{ marginBottom: 24 }}>
                <Col span={12}>
                    <Link to={"/goods/room-manage/room-type/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
            </Row>
            <Card style={{ backgroundColor: "#f2f3f5", marginBottom: 24 }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="roomTypeName" label="房型名称">
                                <Input placeholder="请输入房型名称" allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="roomTypeClassId" label="房型名称">
                                <Select placeholder={"请选择房型分类id"} allowClear >
                                    {roomTypeClassListOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="roomTypeState" label="审核状态">
                                <Select placeholder={"请选择审核状态"} allowClear >
                                    <Select.Option value={10}>审核中</Select.Option>
                                    <Select.Option value={20}>审核通过</Select.Option>
                                    <Select.Option value={30}>审核失败</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button
                            style={{ marginLeft: 70 }}
                            type="primary"
                            htmlType="submit"
                        >
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Table
                loading={isLoading}
                rowKey={columns => columns.roomTypeId}
                pagination={paginationProps}
                scroll={{ x: 1500 }}
                dataSource={list || []}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'roomTypeId',
                        key: 'roomTypeId',
                        align: 'center',
                        fixed: "left",
                        width: 100,
                    },
                    {
                        title: '房型名称',
                        dataIndex: 'roomTypeName',
                        key: 'roomTypeName',
                        width: 150,
                        fixed: "left",
                        align: 'center',
                    },
                    {
                        title: '分类名称',
                        dataIndex: 'roomTypeClassName',
                        key: 'roomTypeClassName',
                        width: 150,
                        align: 'center',
                    },

                    {
                        title: '房型促销语',
                        dataIndex: 'roomTypePromotions',
                        key: 'roomTypePromotions',
                        width: 150,
                        align: 'center',
                    },

                    {
                        title: '图片',
                        dataIndex: 'roomTypePic',
                        key: 'roomTypePic',
                        align: 'center',
                        width: 100,
                        render: (value) => (
                            <Image width={80} src={imgUrl + (shopId ? shopId : "1") + "/" + value} />
                        ),
                    },

                    {
                        title: '房型标签',
                        dataIndex: 'roomTypeTagIdList',
                        key: 'roomTypeTagIdList',
                        width: 150,
                        align: 'center',
                        render: (value, record) => {
                            if (record.tagList) {
                                let tagNameList = record.tagList?.map((item: any) => {
                                    return (<Tag color="geekblue">{item.roomTypeTagName}</Tag>)
                                })
                                return (<Space size={[0, 8]} wrap>
                                    {tagNameList}
                                </Space>)
                            }
                        }
                    },
                    {
                        title: '房型设备',
                        dataIndex: 'roomTypeFacilitiesIdList',
                        key: 'roomTypeFacilitiesIdList',
                        width: 150,
                        align: 'center',
                        render: (value, record) => {
                            if (record.facilitiesList) {
                                let facilitiesNameList = record.facilitiesList?.map((item: any) => {
                                    return (<Tag color="geekblue">{item.roomTypeFacilitiesName}</Tag>)
                                })
                                return (<Space size={[0, 8]} wrap>
                                    {facilitiesNameList}
                                </Space>)
                            }
                        }
                    },
                    {
                        title: '房型价格',
                        dataIndex: 'roomTypePrice',
                        key: 'roomTypePrice',
                        width: 150,
                        align: 'center',
                    },

                    {
                        title: '房型状态',
                        dataIndex: 'roomTypeState',
                        key: 'roomTypeState',
                        width: 150,
                        align: 'center',
                        render: (value) => (value == 10 ? "审核中" : value == 20 ? "审核通过" : "审核失败")
                    },


                    {
                        title: '房型违规下架原因',
                        dataIndex: 'roomTypeCloseReason',
                        key: 'roomTypeCloseReason',
                        width: 150,
                        align: 'center',
                        render(value, record, index) {
                            if (record?.roomTypeState == 30) {
                                return (value)
                            }
                            return ("");
                        },
                    },

                    {
                        title: '房型添加时间',
                        dataIndex: 'roomTypeAddTime',
                        key: 'roomTypeAddTime',
                        align: 'center',
                        fixed: "right",
                        width: 250,
                        render: (value) => (
                            <span>
                                {value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : '无'}
                            </span>
                        ),
                    },


                    {
                        title: '是否显示',
                        dataIndex: 'roomTypeShow',
                        key: 'roomTypeShow',
                        align: 'center',
                        fixed: "right",
                        width: 100,
                        render: (value) => (
                            <span>{value ? '显示' : '不显示'}</span>
                        ),
                    },

                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        width: 200,
                        fixed: "right",
                        render: (value, roomType) => (
                            <Space size="middle">
                                <Link to={"/goods/room-manage/room-type/update/" + roomType.roomTypeId}>
                                    编辑
                                </Link>
                                <a onClick={() => confirmDeleteRoomType(roomType.roomTypeId)}>
                                    删除
                                </a>
                            </Space>
                        )
                    }
                ]} />
            <ErrorBox error={error} />
        </div>
    )
}