import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Menu,
  message,
  Row,
  Spin,
  Select,
  Radio,
  Upload,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useStorePhoto, useEditStorePhoto } from "../../services/store-photo-service";
import { useStorePhotoAlbumListAll } from "../../services/store-photo-album-service";
import { useDocumentTitle } from "../../utils";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { getToken } from "../../utils/auth-provider";
const { Option } = Select;

export const StorePhotoUpdatePage = () => {
  //标题
  useDocumentTitle("编辑相片", true);
  //API地址
  const apiUrl = process.env.REACT_APP_API_URL;
  const imgUrl = process.env.REACT_APP_IMG_URL;
  const token = getToken();
  const { pathname } = useLocation();
  const storePhotoId = pathname.match(/store\/photo\/update\/(\d+)/)?.[1];
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  //表单
  const [form] = useForm();
  //准备回显数据
  const { data: editingStorePhoto, isLoading } = useStorePhoto(
    Number(storePhotoId)
  );
  //准备专辑列表
  const { data: storePhotoAlbumList } = useStorePhotoAlbumListAll();
  console.log(storePhotoAlbumList);
  const options = storePhotoAlbumList?.data?.map((item: any, value: any) => (
    <Option value={item.storePhotoAlbumId} key={item.storePhotoAlbumId}>
      {item.storePhotoAlbumName}
    </Option>
  ));
  // 图片
  const [uploadLoading, setUploadLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const uploadButton = (
    <div>
      {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  const beforeUpload = (file: any) => {
    setImageUrl("");
    setUploadLoading(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setUploadLoading(false);
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setUploadLoading(false);
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setUploadLoading(false);
      setImageUrl(info?.file?.response?.data?.realPath);
      const relativePath = info?.file?.response?.data?.relativePath;
      form.setFieldsValue({
        storePhotoUrl: relativePath,
      });
    }
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  useEffect(() => {
    const picUrl = imgUrl + editingStorePhoto?.data?.shopId + "/" + editingStorePhoto?.data?.storePhotoUrl;
    setImageUrl(picUrl);
    form.setFieldsValue(editingStorePhoto?.data);
  }, [editingStorePhoto, form]);
  //编辑
  const useMutateEditStorePhoto = useEditStorePhoto;
  const { mutateAsync, error } = useMutateEditStorePhoto();
  //提交表单
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({ ...editingStorePhoto?.data, ...values }).then(() => {
      message.info("编辑成功");
      window.location.href = "/#/store/photo/list";
    });
  };
  return (
    <div>
      <Menu
        style={{ marginBottom: 24 }}
        mode="horizontal"
        selectedKeys={["storePhoto"]}
      >
        <Menu.Item key="storePhoto">
          <Link to={"/store/photo/list"}>
            相片管理
          </Link>
        </Menu.Item>
        <Menu.Item key="searchDefault">
          <Link to={ "/store/photo/album/list"} >
            相册管理
          </Link>
        </Menu.Item>
      </Menu>
      <Spin spinning={isLoading}>
        <Row>
          <Col span={12}>
            <Link to={"/store/photo/list"}>
              <Button type="primary">相片列表</Button>
            </Link>
          </Col>
        </Row>
        <Divider style={{ marginBottom: 24 }} />
        <Form form={form} {...layout} onFinish={onFinish}>
          <Form.Item
            label={"相片名称"}
            name={"storePhotoName"}
            rules={[{ required: true, message: "相片名称必填" }]}
          >
            <Input placeholder={"请输入相片名称"} />
          </Form.Item>

          <Form.Item
            label={"所属相册"}
            name={"storePhotoAlbumId"}
            rules={[{ required: true, message: "所属相册必选" }]}
          >
            <Form.Item name={"storePhotoAlbumId"} noStyle>
              <Select
                showSearch
                placeholder="请选择所属相册"
                optionFilterProp="children"
              >
                {/* <Option value={0} key={0}>
                  请选择
                </Option> */}
                {options}
              </Select>
            </Form.Item>
          </Form.Item>

          <Form.Item
            name={"uploadPic"}
            label={"相片"}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: "相片必传" }]}
            style={{ textAlign: "left" }}
            initialValue={[
              {
              "uid": "",
              "lastModified": "",
              "lastModifiedDate": "",
              "name": "",
              "size": "",
              "type": "",
              "percent": "",
              "originFileObj": {
                  "uid": ""
              },
              "status": "done",
              "response": {
                  "status": 0,
                  "data": {
                  "relativePath": "",
                  "realPath": ""
                  }
              },
              "xhr": {}
              }
            ]}
          >
            <Upload
              name="imageFile"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={apiUrl + "/upload/img"}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              headers={{ Authorization: "Bearer " + token }}
            >
              {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: "100%" }} /> : uploadButton}
            </Upload>
          </Form.Item>

          <Form.Item
            hidden
            label={"相片"}
            name={"storePhotoUrl"}
            rules={[{ required: true, message: "相片必传" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={"相片排序"}
            name={"storePhotoSort"}
            rules={[{ required: true, message: "相片排序必填" }]}
          >
            <Input placeholder={"请输入相片排序"} />
          </Form.Item>

          <Form.Item
            label={"是否显示"}
            name={"storePhotoShow"}
            rules={[{ required: true, message: "相册显示必选" }]}
            style={{ textAlign: "left" }}
          >
            <Radio.Group>
              <Radio value={true}>显示</Radio>
              <Radio value={false}>不显示</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
            <Button loading={submitLoading} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
