// 在真实环境中，如果使用firebase这种第三方auth服务的话，本文件不需要开发者开发

import { StoreAccount } from "../interfaces/store-account";

const apiUrl = process.env.REACT_APP_API_URL;

export const localStorageKey = "__auth_provider_sm_city_mall_store_token__";
export const localStoreId = "__auth_provider_sm_city_mall_store_Id__";
export const localShopId = "__auth_provider_sm_city_mall_store_Id__";

export const getToken = () => window.localStorage.getItem(localStorageKey);
export const getStoreId = () => window.localStorage.getItem(localStoreId);
export const getShopId = () => window.localStorage.getItem(localShopId);

export const handleStoreResponse = ({storeAccount}: {storeAccount: StoreAccount}) => {
    if(storeAccount.token != null) {
        window.localStorage.setItem(localStorageKey, storeAccount.token || '');
        window.localStorage.setItem(localStoreId, String(storeAccount.storeId) || '');
        window.localStorage.setItem(localShopId, String(storeAccount.shopId) || '');
        return storeAccount;
    } else {
        return null;
    }
}

export const login = (data: { storeAccountName: string; storeAccountPassword: string}) => {
    return fetch(`${apiUrl}/sign`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(async (response: any) => {
        if(response.ok) {
            return response.json();
        } else {
            return Promise.reject(data)
        }
    });
}

export const register = (data: {storeAccountName: string, storeAccountPassword: string}) => {
    return fetch(`${apiUrl}/register`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(async (response: Response) => {
        if(response.ok) {
            return handleStoreResponse(await response.json())
        } else {
            return Promise.reject(data)
        }
    });
}

export const logout = async () => window.localStorage.removeItem(localStorageKey) 



