import { useMutation, useQuery, useQueryClient } from "react-query";
import { RoomTypeDetail } from "../interfaces/room-type-detail";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useRoomTypeDetailList = (param?: Partial<RoomTypeDetail>) => {
    const client = useHttp();
    const result = useQuery<any>(['roomTypeDetailPage', param], () => client('roomTypeDetail', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditRoomTypeDetail = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<RoomTypeDetail>) => client(`roomTypeDetail`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('roomTypeDetailPage')
        }
    )
}

export const useAddRoomTypeDetail = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<RoomTypeDetail>) => client(`roomTypeDetail`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('roomTypeDetailPage')
        }
    )
}

export const useDeleteRoomTypeDetail = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ roomTypeDetailId }: { roomTypeDetailId: number}) =>
            client(`roomTypeDetail/${roomTypeDetailId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('roomTypeDetailPage')
        }
    );
};

export const useRoomTypeDetail = (roomTypeDetailId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['roomTypeDetail', {roomTypeDetailId}],
        () => client(`roomTypeDetail/${roomTypeDetailId}`),
        {
            enabled: Boolean(roomTypeDetailId)
        }
    )
}