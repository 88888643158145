import { Button, Col, message, Modal, Row, Space, Table, Image, Card, Form, Input } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { ErrorBox } from "../../components/lib"
import { useRoomTypeTagList, useDeleteRoomTypeTag } from "../../services/room-type-tag-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useRoomTypeTagSearchParams } from "./util"

export const RoomTypeTagListPage = () => {
    useDocumentTitle('房型标签列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const [param, setParam] = useRoomTypeTagSearchParams()
    const {isLoading, error, totalElements, data: list} = useRoomTypeTagList(useDebounce(param, 200))

	//分页
	const paginationProps = {
	  showSizeChanger: true,
	  onShowSizeChange: (current: number, pageSize: number) => {
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		});
	  },
	  defaultCurrent: 1,
	  pageSize: param.pageSize,
	  total: totalElements,
	  onChange: (current: number, pageSize: number) =>
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		}),
	};
     //搜索
  const onFinish = (values: any) => {
    setParam({
      ...param,
      roomTypeTagName: values.roomTypeTagName,
      pageNum: null,
    });
  };
    //删除事件
    const { mutateAsync } = useDeleteRoomTypeTag();
    const confirmDeleteRoomTypeTag = (roomTypeTagId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ roomTypeTagId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                          } else {
                            message.error(data.msg)
                          }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/goods/room-manage/room-type-tag/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
            </Row>
            <Card style={{ backgroundColor: "#f2f3f5", marginBottom: 24 }}>
			  <Form onFinish={onFinish}>
				<Row gutter={20}>
				  <Col span={8}>
					<Form.Item name="roomTypeTagName" label="房型标签名称">
					  <Input placeholder="请输入房型标签名称" allowClear />
					</Form.Item>
				  </Col>
				</Row>
				<Form.Item style={{ marginBottom: 0 }}>
				  <Button
					style={{ marginLeft: 70 }}
					type="primary"
					htmlType="submit"
				  >
					提交
				  </Button>
				</Form.Item>
			  </Form>
			</Card>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.roomTypeTagId } 
                pagination = { paginationProps }
                scroll={{x:1500}}
            	dataSource={ list || [] }
                columns = {[
                {
                    title: 'ID',
                    dataIndex: 'roomTypeTagId',
                    key: 'roomTypeTagId',
                    align: 'center',
                    width: 100,
                },

                {
                    title: '标签名称',
                    dataIndex: 'roomTypeTagName',
                    key: 'roomTypeTagName',
                    width: 150,
                    align: 'center',
                },

                {
                    title: '门店id',
                    dataIndex: 'storeId',
                    key: 'storeId',
                    width: 150,
                    align: 'center',
                },

                {
                    title: '商家id',
                    dataIndex: 'shopId',
                    key: 'shopId',
                    width: 150,
                    align: 'center',
                },

                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    width: 200,
                    fixed:"right",
                    render: (value, roomTypeTag) => (
                        <Space size="middle">
                            <Link to={"/goods/room-manage/room-type-tag/update/" + roomTypeTag.roomTypeTagId}>
                                编辑
                            </Link>
                            <a onClick={() => confirmDeleteRoomTypeTag(roomTypeTag.roomTypeTagId)}>
                                删除
                            </a>
                        </Space>
                    )
                }
            ]} />
            <ErrorBox error={ error } />
        </div>
    )
}