import { Button, Col, Divider, Form, Input, message, Radio, Row, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRoomTypeDetail, useEditRoomTypeDetail } from "../../services/room-type-detail-service";
import { useDocumentTitle } from "../../utils"
import  UploadPic  from '../../components/upload-pic';
export const RoomTypeDetailUpdatePage = () => {
    //标题
    useDocumentTitle('编辑房型详情', true)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const roomTypeDetailId = Number(pathname.match(/goods\/room-manage\/room-type-detail\/update\/(\d+)/)?.[1])

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //表单
    const [form] = useForm();
    const [roomTypeDetailPic, setRoomTypeDetailPic] = useState("");
    const [uploading, setUploading] = useState<boolean>(false);

    //准备回显
    const { data: editingRoomTypeDetail, isLoading } = useRoomTypeDetail(roomTypeDetailId);
    console.log("回显",editingRoomTypeDetail,isLoading);
    useEffect(() => {
        form.setFieldsValue({ ...editingRoomTypeDetail?.data });
        //封面
        if (editingRoomTypeDetail?.data?.roomTypeDetailPic) {
            setRoomTypeDetailPic(editingRoomTypeDetail?.data?.roomTypeDetailPic)
        }
    }, [editingRoomTypeDetail, form]);

    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useEditRoomTypeDetail();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingRoomTypeDetail?.data, ...values, roomTypeDetailPic }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/goods/room-manage/room-type-detail/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/goods/room-manage/room-type-detail/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >
               	<Form.Item
                    label={"房型详细内容"}
                    name={"roomTypeContent"}
                    rules={[{ required: false, message: "房型详细内容必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入房型详细内容"} />
                </Form.Item>

               	<Form.Item
                    label={"房型id"}
                    name={"roomTypeId"}
                    rules={[{ required: true, message: "房型id必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入房型id"} />
                </Form.Item>

               	<Form.Item
                    label={"门店id"}
                    name={"storeId"}
                    rules={[{ required: true, message: "门店id必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入门店id"} />
                </Form.Item>

               	<Form.Item
                    label={"商户id"}
                    name={"shopId"}
                    rules={[{ required: true, message: "商户id必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入商户id"} />
                </Form.Item>

				<Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}