import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Spin,
  Radio,
  Upload,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useStoreFacilities, useEditStoreFacilities } from "../../services/store-facilities-service";
import { useDocumentTitle } from "../../utils";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { getToken } from "../../utils/auth-provider";

export const StoreFacilitiesUpdatePage = () => {
  //标题
  useDocumentTitle("编辑设备", true);
  //API地址
  const apiUrl = process.env.REACT_APP_API_URL;
  const imgUrl = process.env.REACT_APP_IMG_URL;
  const token = getToken();
  const { pathname } = useLocation();
  const storeFacilitiesId = pathname.match(/store\/facilities\/update\/(\d+)/)?.[1];
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  //表单
  const [form] = useForm();
  //准备回显数据
  const { data: editingStoreFacilities, isLoading } = useStoreFacilities(
    Number(storeFacilitiesId)
  );
  // 图片
  const [uploadLoading, setUploadLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const uploadButton = (
    <div>
      {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  const beforeUpload = (file: any) => {
    setImageUrl("");
    setUploadLoading(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setUploadLoading(false);
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setUploadLoading(false);
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setUploadLoading(false);
      setImageUrl(info?.file?.response?.data?.realPath);
      const relativePath = info?.file?.response?.data?.relativePath;
      form.setFieldsValue({
        storeFacilitiesPic: relativePath,
      });
    }
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  useEffect(() => {
    const picUrl = imgUrl + editingStoreFacilities?.data?.shopId + "/" + editingStoreFacilities?.data?.storeFacilitiesPic;
    setImageUrl(picUrl);
    form.setFieldsValue(editingStoreFacilities?.data);
  }, [editingStoreFacilities, form]);
  //编辑
  const useMutateEditStoreFacilities = useEditStoreFacilities;
  const { mutateAsync, error } = useMutateEditStoreFacilities();
  //提交表单
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({ ...editingStoreFacilities?.data, ...values }).then(() => {
      message.info("编辑成功");
      window.location.href =
        "/#/store/facilities/list";
    });
  };
  return (
    <div>
      <Spin spinning={isLoading}>
        <Row>
          <Col span={12}>
            <Link to={"/store/facilities/list/" + editingStoreFacilities?.data.storeId}>
              <Button type="primary">设备列表</Button>
            </Link>
          </Col>
        </Row>
        <Divider style={{ marginBottom: 24 }} />
        <Form form={form} {...layout} onFinish={onFinish}>
          <Form.Item
            label={"设备名称"}
            name={"storeFacilitiesName"}
            rules={[{ required: true, message: "设备名称必填" }]}
          >
            <Input placeholder={"请输入设备名称"} />
          </Form.Item>

          <Form.Item
            name={"uploadPic"}
            label={"设备图片"}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            style={{ textAlign: "left" }}
          >
            <Upload
              name="imageFile"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={apiUrl + "/upload/img"}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              headers={{ Authorization: "Bearer " + token }}
            >
              {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: "100%" }} /> : uploadButton}
            </Upload>
          </Form.Item>

          <Form.Item
            hidden
            label={"设备图片"}
            name={"storeFacilitiesPic"}
            rules={[{ required: true, message: "设备图片必传" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={"设备排序"}
            name={"storeFacilitiesSort"}
            rules={[{ required: true, message: "设备排序必填" }]}
          >
            <Input placeholder={"请输入设备排序"} />
          </Form.Item>

          <Form.Item
            label={"是否显示"}
            name={"storeFacilitiesShow"}
            rules={[{ required: true, message: "设备显示必选" }]}
            style={{ textAlign: "left" }}
          >
            <Radio.Group>
              <Radio value={true}>显示</Radio>
              <Radio value={false}>不显示</Radio>
            </Radio.Group>
          </Form.Item>
          
          <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
            <Button loading={submitLoading} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
