import {
  Button,
  Col,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Tabs,
  Upload,
  Typography,
  DatePicker,
  Card,
  Space,
  Spin,
  Tooltip,
} from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useEditGoods, useGoods } from "../../services/goods-service";
import { useGoodsDetail } from "../../services/goods-detail-service";
import { useGoodsSkuListByGoodsId } from "../../services/goods-sku-service";
import { useGoodsClassListTree } from "../../services/goods-class-service";
import { useGoodsSpecListAll } from "../../services/goods-spec-service";
import { useGoodsSpecValueListAll } from "../../services/goods-spec-value-service";
import { useGoodsSkuCreateList } from "../../services/goods-sku-service";
import { useGoodsServeTagListAll } from "../../services/goods-serve-tag-service";
import { useStoreListAll } from "../../services/store-service";
import {
  useTransportListAll,
  useTransportMutation,
  useGoodsTransport,
} from "../../services/transport-service";
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";
import { useDocumentTitle } from "../../utils";
import { getToken } from "../../utils/auth-provider";
import moment from "moment";
const { Option } = Select;
const { TabPane } = Tabs;
const { Text } = Typography;
const { RangePicker } = DatePicker;

export const GoodsUpdatePage = () => {
  //标题
  useDocumentTitle("编辑商品", true);
  //API地址
  const apiUrl = process.env.REACT_APP_API_URL;
  const imgUrl = process.env.REACT_APP_IMG_URL;
  const token = getToken();
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 18 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  const [form] = useForm();
  const { pathname } = useLocation();
  const goodsId = pathname.match(/goods\/manage\/update\/(\d+)/)?.[1];
  //准备分类
  const { data: goodsClassList } = useGoodsClassListTree();
  const options = goodsClassList?.data?.map((item: any, value: any) => (
    <Option value={item.goodsClassId} key={item.goodsClassId}>
      {item.goodsClassName}
    </Option>
  ));
  //商品服务标签
  const { data: goodsServeTagList } = useGoodsServeTagListAll()
  const optionsGoodsServeTag = goodsServeTagList?.map((item: any, value: any) => (
      <Option value={item.goodsServeTagId} key={item.goodsServeTagId}>
          {item.goodsServeTagName}
      </Option>
  ))

   //商品是否可使用
   const [goodsPointsUse, setGoodsPointsUse] = useState(false);
   function changeGoodsPointsUse(e: any){
       setGoodsPointsUse(e.target.value)
   }

  //规格值
  const { data: goodsSpecList } = useGoodsSpecListAll();
  const goodsSpecOptions = goodsSpecList?.map((item: any, value: any) => (
    <Option value={item.goodsSpecId} key={item.goodsSpecId}>
      {item.goodsSpecName}
    </Option>
  ));
  //运费模版
  const { data: transportList } = useTransportListAll();
  const optionsTransport = transportList?.map((item: any, value: any) => (
    <Option value={item.transportId} key={item.transportId}>
      {item.transportName}
    </Option>
  ));
  //商品供货商
  const { data: storeList } = useStoreListAll();
  const optionsStore = storeList?.data?.map((item: any, value: any) => (
    <Option value={item.storeId} key={item.storeId}>
      {item.storeName}
    </Option>
  ));
  //获取商品
  const { data: editingGoods, isLoading } = useGoods(Number(goodsId));
  const [goodsType, setGoodsType] = useState(1);
  const [imageUrl, setImageUrl] = useState("");
  const [fileList, setFileList] = useState<any>([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [imageUrls, setImageUrls] = useState("");
  const [goodsSpecOpenShow, setGoodsSpecOpenShow] = useState(false);
  const [goodsSkuBOList, setGoodsSkuBOList] = useState();
  const [transportChargingType, setTransportChargingType] = useState(1);
  const [goodsUniformFreight, setGoodsUniformFreight] = useState(true);
  const [goodsShowStartTimeStr, setGoodsShowStartTimeStr] = useState("");
  const [goodsExpiryStartTimeStr, setGoodsExpiryStartTimeStr] = useState("");
  const [goodsExpiryEndTimeStr, setGoodsExpiryEndTimeStr] = useState("");
  const [goodsPurchaseLimits, setGoodsPurchaseLimits] = useState(false);
  const [goodsContent, setGoodsContent] = useState("");
  useEffect(() => {
    form.setFieldsValue({
      uploadMore: editingGoods?.data?.goodsMorePicVOList,
      goodsUniformFreight: editingGoods?.data?.goodsUniformFreight,
      goodsServeTagIdList: editingGoods?.data?.goodsServeTagIdList
    });
    form.setFieldsValue(editingGoods?.data);
    setGoodsType(editingGoods?.data?.goodsType);
    //详情图
    const goodsPic =
      imgUrl + editingGoods?.data?.shopId + "/" + editingGoods?.data?.goodsPic;
    if (editingGoods?.data?.goodsPic != null) {
      setImageUrl(goodsPic);
    }
    //多图
    if (editingGoods?.data?.goodsMorePicVOList) {
      setFileList(editingGoods?.data?.goodsMorePicVOList);
    }
    //视频
    if (editingGoods?.data?.goodsVideo) {
      const goodsVideo =
        imgUrl +
        editingGoods?.data?.shopId +
        "/" +
        editingGoods?.data?.goodsVideo;
      setVideoUrl(goodsVideo);
    }
    //视频封面
    if (editingGoods?.data?.goodsVideoCoverPreviewPic) {
      const goodsVideoCoverPreviewPic =
        imgUrl +
        editingGoods?.data?.shopId +
        "/" +
        editingGoods?.data?.goodsVideoCoverPreviewPic;
      setImageUrls(goodsVideoCoverPreviewPic);
    }
    //是否显示多规格
    setGoodsSpecOpenShow(editingGoods?.data?.goodsSpecOpen);
    //快递运费
    setGoodsUniformFreight(editingGoods?.data?.goodsUniformFreight);
    //是否限购
    setGoodsPurchaseLimits(editingGoods?.data?.goodsPurchaseLimits);
    //开售时间
    if (editingGoods?.data?.goodsShowStartTimeStr) {
      setGoodsShowStartTimeStr(editingGoods?.data?.goodsShowStartTimeStr);
      form.setFieldsValue({
        goodsShowStartTimeStr: moment(
          editingGoods?.data?.goodsShowStartTimeStr,
          "YYYY-MM-DD HH:mm:ss"
        ),
      });
    }
    if(editingGoods?.data?.goodsPointsUse != null){
      setGoodsPointsUse(editingGoods?.data?.goodsPointsUse);
  }
    //套餐有效期
    if (editingGoods?.data?.goodsExpiryStartTimeStr && editingGoods?.data?.goodsExpiryEndTimeStr) {
      //套餐有效期 - 开始
      setGoodsExpiryStartTimeStr(editingGoods?.data?.goodsExpiryStartTimeStr);
      form.setFieldsValue({
        goodsExpiryStartTimeStr: moment(
          editingGoods?.data?.goodsExpiryStartTimeStr,
          "YYYY-MM-DD HH:mm:ss"
        ),
      });
      //套餐有效期 - 结束
      setGoodsExpiryEndTimeStr(editingGoods?.data?.goodsExpiryEndTimeStr);
      form.setFieldsValue({
        goodsExpiryEndTimeStr: moment(
          editingGoods?.data?.goodsExpiryEndTimeStr,
          "YYYY-MM-DD HH:mm:ss"
        ),
      });
      //套餐时间
      form.setFieldsValue({
        "goodsExpiryTimeRange": [moment(editingGoods?.data?.goodsExpiryStartTimeStr, "YYYY-MM-DD HH:mm:ss"), moment(editingGoods?.data?.goodsExpiryEndTimeStr, "YYYY-MM-DD HH:mm:ss")]
      })
    }
  }, [editingGoods?.data, form]);
  //多规格
  const { data: goodsSkuList } = useGoodsSkuListByGoodsId(Number(goodsId));
  useEffect(() => {
    setGoodsSkuBOList(goodsSkuList);
    form.setFieldsValue({ goodsSkuBOList: goodsSkuList });
  }, [goodsSkuList, form]);
  //获取商品详情
  const { data: editingGoodsDetail } = useGoodsDetail(Number(goodsId));
  useEffect(() => {
    setGoodsContent(editingGoodsDetail?.data?.goodsContent);
    form.setFieldsValue({
      goodsContent: BraftEditor.createEditorState(
        editingGoodsDetail?.data?.goodsContent
      ),
    });
  }, [editingGoodsDetail, form]);
  //获取运费模版
  const { data: transport } = useGoodsTransport(Number(goodsId));
  useEffect(() => {
    if (transport?.status == 0) {
      setTransportChargingType(transport?.data?.transportChargingType);
    }
  }, [transport, form]);
  //
  //表单
  const [submitLoading, setSubmitLoading] = useState(false);
  //实物或虚拟商品
  function changeGoodsType(e: any) {
    setGoodsType(e.target.value);
  }
  //上传商品主图
  const [uploadLoading, setUploadLoading] = useState(false);
  const uploadButton = (
    <div>
      {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  const beforeUpload = (file: any) => {
    setImageUrl("");
    setUploadLoading(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setUploadLoading(false);
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setUploadLoading(false);
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setUploadLoading(false);
      setImageUrl(info?.file?.response?.data?.realPath);
      const relativePath = info?.file?.response?.data?.relativePath;
      form.setFieldsValue({
        goodsPic: relativePath,
      });
    }
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //上传多图
  const uploadMoreButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  const handleChangeMore = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setFileList(info?.fileList);
    }
    if (info.file.status === "removed") {
      setFileList(info?.fileList);
    }
  };
  const beforeUploadMore = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const normMoreFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //上传视频
  const [uploadVideoLoading, setUploadVideoLoading] = useState(false);
  const uploadVideoButton = (
    <div>
      {uploadVideoLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传视频</div>
    </div>
  );
  const beforeVideoUpload = (file: any) => {
    setVideoUrl("");
    setUploadVideoLoading(true);
    const isLt20M = file.size / 1024 / 1024 < 20;
    if (!isLt20M) {
      setUploadVideoLoading(false);
      message.error("Image must smaller than 20MB!");
    }
    return isLt20M;
  };
  const handleVideoChange = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setUploadVideoLoading(false);
      setVideoUrl(info?.file?.response?.data?.realPath);
      const relativePath = info?.file?.response?.data?.relativePath;
      form.setFieldsValue({
        goodsVideo: relativePath,
      });
    }
  };
  const normVideoFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //上传视频封面
  const [uploadLoadings, setUploadLoadings] = useState(false);
  const uploadButtons = (
    <div>
      {uploadLoadings ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  const beforeUploads = (file: any) => {
    setImageUrls("");
    setUploadLoadings(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setUploadLoadings(false);
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setUploadLoadings(false);
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChanges = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setUploadLoadings(false);
      setImageUrls(info?.file?.response?.data?.realPath);
      const relativePath = info?.file?.response?.data?.relativePath;
      form.setFieldsValue({
        goodsVideoCoverPreviewPic: relativePath,
      });
    }
  };
  const normFiles = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //多规格
  //执行查询规格值 / useGoodsSpecValueList
  const useMutateGoodsSpecValueList = useGoodsSpecValueListAll;
  const { mutateAsync: mutateAsyncGoodsSpecValueList } =
    useMutateGoodsSpecValueList();
  //goodsSpecIds
  const useMutateGoodsSkuCreateList = useGoodsSkuCreateList;
  const { mutateAsync: mutateAsyncGoodsSkuCreateList } =
    useMutateGoodsSkuCreateList();
  const [goodsSpecValueOptions1, setGoodsSpecValueOptions1] = useState();
  function changeGoodsSpecId1(value: any) {
    const goodsSpecId = value;
    mutateAsyncGoodsSpecValueList({ goodsSpecId }).then((data: any) => {
      const goodsSpecValueList = data.data;
      const goodsSpecValueOptions = goodsSpecValueList?.map(
        (item: any, value: any) => (
          <Option value={item.goodsSpecValueId} key={item.goodsSpecValueId}>
            {item.goodsSpecValueName}
          </Option>
        )
      );
      setGoodsSpecValueOptions1(goodsSpecValueOptions);
    });
  }
  const [goodsSpecValueOptions2, setGoodsSpecValueOptions2] = useState();
  function changeGoodsSpecId2(value: any) {
    const goodsSpecId = value;
    mutateAsyncGoodsSpecValueList({ goodsSpecId }).then((data: any) => {
      const goodsSpecValueList = data.data;
      const goodsSpecValueOptions = goodsSpecValueList?.map(
        (item: any, value: any) => (
          <Option value={item.goodsSpecValueId} key={item.goodsSpecValueId}>
            {item.goodsSpecValueName}
          </Option>
        )
      );
      setGoodsSpecValueOptions2(goodsSpecValueOptions);
    });
  }
  const [goodsSpecValueOptions3, setGoodsSpecValueOptions3] = useState();
  function changeGoodsSpecId3(value: any) {
    const goodsSpecId = value;
    mutateAsyncGoodsSpecValueList({ goodsSpecId }).then((data: any) => {
      const goodsSpecValueList = data.data;
      const goodsSpecValueOptions = goodsSpecValueList?.map(
        (item: any, value: any) => (
          <Option value={item.goodsSpecValueId} key={item.goodsSpecValueId}>
            {item.goodsSpecValueName}
          </Option>
        )
      );
      setGoodsSpecValueOptions3(goodsSpecValueOptions);
    });
  }
  const [goodsSpecValue1, setGoodsSpecValue1] = useState([]);
  const [goodsSpecValue2, setGoodsSpecValue2] = useState([]);
  const [goodsSpecValue3, setGoodsSpecValue3] = useState([]);
  function handleChange1(value: any) {
    setGoodsSpecValue1(value);
    mutateAsyncGoodsSkuCreateList([
      ...value,
      ...goodsSpecValue2,
      ...goodsSpecValue3,
    ]).then((data) => {
      setGoodsSkuBOList(data.data);
      form.setFieldsValue({ goodsSkuBOList: data.data });
    });
  }
  function handleChange2(value: any) {
    setGoodsSpecValue2(value);
    mutateAsyncGoodsSkuCreateList([
      ...goodsSpecValue1,
      ...value,
      ...goodsSpecValue3,
    ]).then((data) => {
      setGoodsSkuBOList(data.data);
      form.setFieldsValue({ goodsSkuBOList: data.data });
    });
  }
  function handleChange3(value: any) {
    setGoodsSpecValue3(value);
    mutateAsyncGoodsSkuCreateList([
      ...goodsSpecValue1,
      ...goodsSpecValue2,
      ...value,
    ]).then((data) => {
      setGoodsSkuBOList(data.data);
      form.setFieldsValue({ goodsSkuBOList: data.data });
    });
  }
  function changeGoodsSpecOpen(e: any) {
    setGoodsSpecOpenShow(e.target.value);
  }
  const [goodsSpecNum, setGoodsSpecNum] = useState(1);
  function changeGoodsSpecNum(e: any) {
    setGoodsSpecNum(goodsSpecNum + 1);
  }
  //重量是否必填
  const { mutateAsync: mutateAsyncTransport } = useTransportMutation();
  function handleTransportChange(transportId: number) {
    mutateAsyncTransport({ transportId }).then((data:any) => {
      const transportChargingType = data.data.transportChargingType;
      setTransportChargingType(transportChargingType);
    });
  }
  //快递运费
  const handleGoodsUniformFreightChange = (e: any) => {
    setGoodsUniformFreight(e.target.value);
    if (e.target.value == true) {
      setTransportChargingType(1);
    }
    form.setFieldsValue({
      transportId: null,
    });
  };
  //套餐有效期
  const handleSelectTime = (value:any ,dateString:any) => {
      setGoodsExpiryStartTimeStr(dateString[0]);
      setGoodsExpiryEndTimeStr(dateString[1]);
  }
  //上架时间
  const handleSelectGoodsShowStartTime = (value: any, dateString: any) => {
    setGoodsShowStartTimeStr(dateString);
  };
  //商品限购
  function changeGoodsPurchaseLimits(e: any){
      setGoodsPurchaseLimits(e.target.value)
  }
  //富文本
  const handleEditorChange = (editorState: any) => {
    setGoodsContent(editorState.toHTML());
  };
  //富文本上传
  const myUploadFn = (param: any) => {
    const serverURL = apiUrl + "/upload/braftEditorImg";
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    const successFn = (response: any) => {
      // 假设服务端直接返回文件上传后的地址
      // 上传成功后调用param.success并传入上传后的文件地址
      param.success({
        url: xhr.responseText,
        meta: {
          loop: true, // 指定音视频是否循环播放
          autoPlay: true, // 指定音视频是否自动播放
          controls: true, // 指定音视频是否显示控制栏
        },
      });
    };
    const progressFn = (event: any) => {
      // 上传进度发生变化时调用param.progress
      param.progress((event.loaded / event.total) * 100);
    };
    const errorFn = (response: any) => {
      // 上传发生错误时调用param.error
      param.error({
        msg: "unable to upload.",
      });
    };
    xhr.upload.addEventListener("progress", progressFn, false);
    xhr.addEventListener("load", successFn, false);
    xhr.addEventListener("error", errorFn, false);
    xhr.addEventListener("abort", errorFn, false);
    fd.append("file", param.file);
    xhr.open("POST", serverURL, true);
    xhr.setRequestHeader("name", "imageFile");
    xhr.setRequestHeader("Authorization", "Bearer " + token);
    xhr.send(fd);
  };
  //提交
  const useMutateEditGoods = useEditGoods;
  const { mutateAsync: mutateAsyncEditGoods, error } = useMutateEditGoods();
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    if (values.goodsShow == 2) {
      if (goodsShowStartTimeStr.length == 0) {
        setSubmitLoading(false);
        message.error("开售时间必选");
        return;
      }
    }
    if (values.goodsUniformFreight == true) {
      if (values.goodsFreight == null) {
        setSubmitLoading(false);
        message.error("请输入快递运费");
        return;
      }
    }
    var goodsMorePic = "";
    fileList?.map((item: any, value: any) => {
      goodsMorePic = goodsMorePic + item?.response + ",";
    });
    const goodsSkuBOList = values?.goodsSkuBOList;
    const goodsId = editingGoods?.data?.goodsId;
    mutateAsyncEditGoods({
      ...values,
      goodsId,
      goodsMorePic,
      goodsExpiryStartTimeStr,
      goodsExpiryEndTimeStr,
      goodsShowStartTimeStr,
      goodsContent,
      goodsSkuBOList,
    }).then(() => {
      window.location.href = "/#/goods/manage/list";
    });
  };
  return (
    <div>
      <Row style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Link to={"/goods/manage/list"}>
            <Button type="primary">商品列表</Button>
          </Link>
        </Col>
      </Row>
      <Form form={form} {...layout} name="basic" onFinish={onFinish}>
        <Spin spinning={isLoading}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="编辑基本信息" key="1">
              <div
                style={{
                  padding: 10,
                  backgroundColor: "#f8f8f8",
                  fontSize: 14,
                  fontWeight: 700,
                }}
              >
                基本信息
              </div>

              <Form.Item
                label={"商品类型"}
                name={"goodsType"}
                rules={[{ required: true, message: "商品类型必选" }]}
                style={{ marginTop: 24 }}
              >
                <Radio.Group onChange={changeGoodsType}>
                  <Radio value={1}>实物商品（物流发货）</Radio>
                  <Radio value={2}>虚拟商品（无需物流）</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label={"商品名称"}
                name={"goodsName"}
                rules={[{ required: true, message: "商品名称必填" }]}
                style={{ marginTop: 24 }}
              >
                <Input style={{ width: 380 }} placeholder={"请输入商品名称"} />
              </Form.Item>

              <Form.Item
                label={"商品卖点"}
                name={"goodsPromotions"}
                rules={[{ required: true, message: "商品卖点必填" }]}
              >
                <Input style={{ width: 380 }} placeholder={"请输入商品卖点"} />
              </Form.Item>

              <Form.Item label={"商品SPU"} name={"goodsSpu"}>
                <Input style={{ width: 380 }} placeholder={"请输入商品SPU"} />
              </Form.Item>

              <Form.Item
                label={"商品分类"}
                name={"goodsClassId"}
                rules={[{ required: true, message: "商品分类必选" }]}
              >
                <Select
                  showSearch
                  placeholder="请选择商品分类"
                  optionFilterProp="children"
                  style={{ textAlign: "left", width: 380 }}
                >
                  {options}
                </Select>
              </Form.Item>

              <Form.Item
                label={"商品服务标签"}
                name={"goodsServeTagIdList"}
              >
                <Select
                  mode="multiple"
                  showSearch
                  placeholder="请选择商品服务标签"
                  optionFilterProp="children"
                  style={{textAlign:'left', width: 380}}
                >
                  <Option value={0}>请选择</Option>
                  {optionsGoodsServeTag}
                </Select>
              </Form.Item>

              {/* 商品主图 */}
              <Form.Item
                name={"uploadPic"}
                label={"商品主图"}
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ required: true, message: "商品主图必传" }]}
                style={{ textAlign: "left" }}
                initialValue={[
                  {
                    uid: "",
                    lastModified: "",
                    lastModifiedDate: "",
                    name: "",
                    size: "",
                    type: "",
                    percent: "",
                    originFileObj: {
                      uid: "",
                    },
                    status: "done",
                    response: {
                      status: 0,
                      data: {
                        relativePath: "",
                        realPath: "",
                      },
                    },
                    xhr: {},
                  },
                ]}
              >
                <Upload
                  name="imageFile"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action={apiUrl + "/upload/img"}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                  headers={{ Authorization: "Bearer " + token }}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>

              <Form.Item
                hidden
                label={"商品主图"}
                name={"goodsPic"}
                rules={[{ required: true, message: "商品主图必传" }]}
              >
                <Input />
              </Form.Item>

              {/* 商品详情图 */}
              <Form.Item
                name={"uploadMore"}
                label={"商品详情图"}
                valuePropName="fileList"
                getValueFromEvent={normMoreFile}
                rules={[{ required: true, message: "商品详情图必传" }]}
                style={{ textAlign: "left" }}
              >
                <Upload
                  name="imageFile"
                  action={apiUrl + "/upload/antdImg"}
                  listType="picture-card"
                  fileList={fileList}
                  beforeUpload={beforeUploadMore}
                  onChange={handleChangeMore}
                  headers={{ Authorization: "Bearer " + token }}
                >
                  {fileList.length < 5 ? uploadMoreButton : ""}
                </Upload>
              </Form.Item>

              <Form.Item
                name={"uploadVideo"}
                label={"商品视频"}
                valuePropName="fileList"
                getValueFromEvent={normVideoFile}
                style={{ textAlign: "left" }}
              >
                <Upload
                  name="videoFile"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action={apiUrl + "/upload/video"}
                  beforeUpload={beforeVideoUpload}
                  onChange={handleVideoChange}
                  headers={{ Authorization: "Bearer " + token }}
                >
                  {videoUrl ? (
                    <video src={videoUrl} style={{ width: "100%" }} />
                  ) : (
                    uploadVideoButton
                  )}
                </Upload>
              </Form.Item>

              <Form.Item hidden label={"商品视频"} name={"goodsVideo"}>
                <Input />
              </Form.Item>

              <Form.Item
                name={"uploadPics"}
                label={"视频贴片"}
                valuePropName="fileList"
                getValueFromEvent={normFiles}
                style={{ textAlign: "left" }}
              >
                <Upload
                  name="imageFile"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action={apiUrl + "/upload/img"}
                  beforeUpload={beforeUploads}
                  onChange={handleChanges}
                  headers={{ Authorization: "Bearer " + token }}
                >
                  {imageUrls ? (
                    <img
                      src={imageUrls}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButtons
                  )}
                </Upload>
                <Text style={{ color: "#999", fontSize: 12 }}>
                  视频贴片是指视频播放器在播放前展示的图片。
                </Text>
              </Form.Item>

              <Form.Item
                hidden
                label={"视频贴片"}
                name={"goodsVideoCoverPreviewPic"}
              >
                <Input />
              </Form.Item>

              <div
                style={{
                  padding: 10,
                  backgroundColor: "#f8f8f8",
                  fontSize: 14,
                  fontWeight: 700,
                }}
              >
                价格库存
              </div>

              <Form.Item
                label={"统一规格"}
                name={"goodsSpecOpen"}
                rules={[{ required: true, message: "统一规格必选" }]}
                style={{ marginTop: 24, textAlign: "left" }}
              >
                <Radio.Group onChange={changeGoodsSpecOpen}>
                  <Radio value={false}>是</Radio>
                  <Radio value={true}>否</Radio>
                </Radio.Group>
              </Form.Item>

              {!goodsSpecOpenShow ? (
                <Form.Item
                  label={"商品规格"}
                  name={"goodsSpecName"}
                  rules={[{ required: true, message: "商品规格必填" }]}
                  style={{ marginTop: 24 }}
                >
                  <Input
                    style={{ width: 380 }}
                    placeholder={"请输入商品规格"}
                  />
                </Form.Item>
              ) : (
                ""
              )}

              {!goodsSpecOpenShow ? (
                <Form.Item
                  label={"商品价格"}
                  name={"goodsPrice"}
                  rules={[{ required: true, message: "商品价格必填" }]}
                  style={{ marginTop: 24 }}
                >
                  <Input
                    style={{ width: 380 }}
                    placeholder={"请输入商品价格"}
                  />
                </Form.Item>
              ) : (
                ""
              )}

              {!goodsSpecOpenShow ? (
                <Form.Item
                  label={"商品划线价"}
                  name={"goodsOriginalPrice"}
                  rules={[{ required: true, message: "商品划线价必填" }]}
                  style={{ marginTop: 24 }}
                >
                  <Input
                    style={{ width: 380 }}
                    placeholder={"请输入商品划线价"}
                  />
                </Form.Item>
              ) : (
                ""
              )}

              {!goodsSpecOpenShow ? (
                <Form.Item
                  label={"商品成本价"}
                  name={"goodsCostPrice"}
                  rules={[{ required: true, message: "商品成本价必填" }]}
                  style={{ marginTop: 24 }}
                >
                  <Input
                    style={{ width: 380 }}
                    placeholder={"请输入商品成本价"}
                  />
                </Form.Item>
              ) : (
                ""
              )}

              {!goodsSpecOpenShow ? (
                <Form.Item
                  label={"商品库存"}
                  name={"goodsStorage"}
                  rules={[{ required: true, message: "商品库存必填" }]}
                  style={{ marginTop: 24 }}
                >
                  <Input
                    style={{ width: 380 }}
                    placeholder={"请输入商品库存"}
                  />
                </Form.Item>
              ) : (
                ""
              )}

              {!goodsSpecOpenShow && transportChargingType == 1 ? (
                <Form.Item label={"商品重量"} name={"goodsWeight"}>
                  <Input
                    addonAfter="kg"
                    style={{ width: 380 }}
                    placeholder={"请输入商品重量"}
                    suffix={
                      <Tooltip title="该重量用于运费模板中的计重收费。">
                        <QuestionCircleOutlined
                          style={{ color: "rgba(0,0,0,.45)" }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              ) : (
                ""
              )}

              {!goodsSpecOpenShow && transportChargingType == 2 ? (
                <Form.Item
                  label={"商品重量"}
                  name={"goodsWeight"}
                  rules={[{ required: true, message: "商品重量必填" }]}
                >
                  <Input
                    addonAfter="kg"
                    style={{ width: 380 }}
                    placeholder={"请输入商品重量"}
                    suffix={
                      <Tooltip title="该重量用于运费模板中的计重收费。">
                        <QuestionCircleOutlined
                          style={{ color: "rgba(0,0,0,.45)" }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              ) : (
                ""
              )}

              {goodsSpecOpenShow ? (
                <Form.Item label={"商品规格"}>
                  <Card
                    style={{
                      width: 900,
                      boxShadow: "0 0 4px 0 rgb(200 200 200 / 50%)",
                    }}
                  >
                    {goodsSpecNum > 0 ? (
                      <div>
                        <Form.Item label={"规格名"} name={"goodsSpecId1"}>
                          <Select
                            showSearch
                            filterOption={false}
                            placeholder="请选择规格名"
                            optionFilterProp="children"
                            style={{ textAlign: "left", width: 380 }}
                            onChange={changeGoodsSpecId1}
                          >
                            {goodsSpecOptions}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label={"规格值"}
                          name={"goodsSpecValueId1"}
                          style={{ marginBottom: 40 }}
                        >
                          <Select
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            placeholder="请选择规格值"
                            optionFilterProp="children"
                            style={{ textAlign: "left", width: 380 }}
                            onChange={handleChange1}
                          >
                            {goodsSpecValueOptions1}
                          </Select>
                        </Form.Item>
                      </div>
                    ) : (
                      ""
                    )}

                    {goodsSpecNum > 1 ? (
                      <div>
                        <Form.Item label={"规格名"} name={"goodsSpecId2"}>
                          <Select
                            showSearch
                            filterOption={false}
                            placeholder="请选择商品规格"
                            optionFilterProp="children"
                            style={{ textAlign: "left", width: 380 }}
                            onChange={changeGoodsSpecId2}
                          >
                            {goodsSpecOptions}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label={"规格值"}
                          name={"goodsSpecValueId2"}
                          style={{ marginBottom: 40 }}
                        >
                          <Select
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            placeholder="请选择规格值"
                            optionFilterProp="children"
                            style={{ textAlign: "left", width: 380 }}
                            onChange={handleChange2}
                          >
                            {goodsSpecValueOptions2}
                          </Select>
                        </Form.Item>
                      </div>
                    ) : (
                      ""
                    )}

                    {goodsSpecNum > 2 ? (
                      <div>
                        <Form.Item label={"规格名"} name={"goodsSpecId3"}>
                          <Select
                            showSearch
                            filterOption={false}
                            placeholder="请选择商品规格"
                            optionFilterProp="children"
                            style={{ textAlign: "left", width: 380 }}
                            onChange={changeGoodsSpecId3}
                          >
                            {goodsSpecOptions}
                          </Select>
                        </Form.Item>
                        <Form.Item label={"规格值"} name={"goodsSpecValueId3"}>
                          <Select
                            filterOption={false}
                            mode="multiple"
                            showSearch
                            placeholder="请选择规格值"
                            optionFilterProp="children"
                            style={{ textAlign: "left", width: 380 }}
                            onChange={handleChange3}
                          >
                            {goodsSpecValueOptions3}
                          </Select>
                        </Form.Item>
                      </div>
                    ) : (
                      ""
                    )}
                    {goodsSpecNum < 3 ? (
                      <Button onClick={changeGoodsSpecNum}>新增规格</Button>
                    ) : (
                      ""
                    )}
                  </Card>
                </Form.Item>
              ) : (
                ""
              )}

              {goodsSpecOpenShow == true ? (
                <Form.Item label={"规格明细"} name={"spec"}>
                  <Card
                    style={{
                      width: 900,
                      boxShadow: "0 0 4px 0 rgb(200 200 200 / 50%)",
                    }}
                  >
                    <Space
                      style={{ display: "flex", marginBottom: 0 }}
                      align="baseline"
                    >
                      <Form.Item
                        style={{
                          width: 10,
                          marginBottom: 10,
                          textAlign: "center",
                          display: "none",
                        }}
                      >
                        规格值ID
                      </Form.Item>
                      <Form.Item
                        style={{
                          width: 300,
                          marginBottom: 10,
                          textAlign: "center",
                        }}
                      >
                        规格名
                      </Form.Item>
                      <Form.Item
                        style={{
                          width: 90,
                          marginBottom: 10,
                          textAlign: "center",
                        }}
                      >
                        价格
                      </Form.Item>
                      <Form.Item
                        style={{
                          width: 90,
                          marginBottom: 10,
                          textAlign: "center",
                        }}
                      >
                        原价
                      </Form.Item>
                      <Form.Item
                        style={{
                          width: 90,
                          marginBottom: 10,
                          textAlign: "center",
                        }}
                      >
                        成本价
                      </Form.Item>
                      <Form.Item
                        style={{
                          width: 90,
                          marginBottom: 10,
                          textAlign: "center",
                        }}
                      >
                        重量
                        <Tooltip
                          placement="topLeft"
                          title="该重量用于运费模板中的计重收费"
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </Form.Item>
                      <Form.Item
                        style={{
                          width: 90,
                          marginBottom: 10,
                          textAlign: "center",
                        }}
                      >
                        库存
                      </Form.Item>
                    </Space>

                    <Form.List
                      initialValue={goodsSkuBOList}
                      name="goodsSkuBOList"
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field, index) => (
                            <Space
                              key={field.key}
                              style={{ display: "flex", marginBottom: 8 }}
                              align="baseline"
                            >
                              <Space>
                                <Form.Item
                                  name={[field.name, "goodsSkuId"]}
                                  style={{ display: "none" }}
                                >
                                  <Input style={{ width: 10 }} />
                                </Form.Item>
                                <Form.Item
                                  name={[field.name, "goodsSkuName"]}
                                  rules={[
                                    { required: true, message: "规格名必填" },
                                  ]}
                                >
                                  <Input style={{ width: 300 }} />
                                </Form.Item>
                                <Form.Item
                                  name={[field.name, "goodsSkuPrice"]}
                                  rules={[
                                    { required: true, message: "价格必填" },
                                  ]}
                                >
                                  <Input style={{ width: 90 }} />
                                </Form.Item>
                                <Form.Item
                                  name={[field.name, "goodsSkuOriginalPrice"]}
                                  rules={[
                                    { required: true, message: "原价必填" },
                                  ]}
                                >
                                  <Input style={{ width: 90 }} />
                                </Form.Item>
                                <Form.Item
                                  name={[field.name, "goodsSkuCostPrice"]}
                                  rules={[
                                    { required: true, message: "成本价必填" },
                                  ]}
                                >
                                  <Input style={{ width: 90 }} />
                                </Form.Item>
                                {transportChargingType == 1 ? (
                                  <Form.Item
                                    name={[field.name, "goodsSkuWeight"]}
                                  >
                                    <Input style={{ width: 90 }} />
                                  </Form.Item>
                                ) : (
                                  ""
                                )}
                                {transportChargingType == 2 ? (
                                  <Form.Item
                                    name={[field.name, "goodsSkuWeight"]}
                                    rules={[
                                      { required: true, message: "重量必填" },
                                    ]}
                                  >
                                    <Input style={{ width: 90 }} />
                                  </Form.Item>
                                ) : (
                                  ""
                                )}
                                <Form.Item
                                  name={[field.name, "goodsSkuStorage"]}
                                  rules={[
                                    { required: true, message: "库存必填" },
                                  ]}
                                >
                                  <Input style={{ width: 90 }} />
                                </Form.Item>
                              </Space>
                              {/* <MinusCircleOutlined onClick={() => remove(field.name)} /> */}
                            </Space>
                          ))}
                        </>
                      )}
                    </Form.List>
                  </Card>
                </Form.Item>
              ) : (
                ""
              )}

              {goodsType == 1 ? (
                <div
                  style={{
                    padding: 10,
                    backgroundColor: "#f8f8f8",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  物流信息
                </div>
              ) : (
                ""
              )}

              {goodsType == 1 ? (
                <Form.Item
                  label={"快递运费"}
                  name={"goodsUniformFreight"}
                  rules={[{ required: true, message: "快递运费必选" }]}
                  style={{ textAlign: "left", marginTop: 24 }}
                >
                  <Radio.Group onChange={handleGoodsUniformFreightChange}>
                    <Space direction="vertical">
                      <Radio value={true}>
                        <Form.Item
                          colon={false}
                          label={"统一邮费"}
                          name={"goodsFreight"}
                          style={{ marginTop: 0, marginBottom: 0 }}
                        >
                          <Input
                            name={"goodsFreight"}
                            prefix={"¥"}
                            style={{ width: 180 }}
                            placeholder={"请输入邮费价格"}
                          />
                        </Form.Item>
                      </Radio>
                      <Radio value={false}>运费模版</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              ) : (
                ""
              )}

              {goodsType == 1 && goodsUniformFreight == false ? (
                <Form.Item
                  label={"运费模版"}
                  name={"transportId"}
                  rules={[{ required: true, message: "运费模版必选" }]}
                >
                  <Select
                    showSearch
                    placeholder="请选择运费模版"
                    optionFilterProp="children"
                    style={{ textAlign: "left", width: 380 }}
                    onChange={handleTransportChange}
                  >
                    {optionsTransport}
                  </Select>
                </Form.Item>
              ) : (
                ""
              )}

              {goodsType == 2? <div style={{ padding: 10, backgroundColor: '#f8f8f8', fontSize: 14, fontWeight: 700}}>
                使用时间/次数
              </div> : ""}

              {goodsType == 2? <Form.Item
                label={"有效期"}
                name={"goodsExpiryType"}
                rules={[{ required: true, message: "有效期类型必选" }]}
                style={{ marginTop: 24, textAlign:'left' }}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio defaultChecked value={1}>永久有效</Radio>
                    <Radio value={2}>
                      <Form.Item
                        colon={false}
                        label={"固定日期"}
                        name={"goodsExpiryTimeRange"}
                        style={{marginTop: 0, marginBottom: 0}}
                      >
                        <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" />
                      </Form.Item>
                    </Radio>
                    <Radio value={3}>
                      <Form.Item
                        colon={false}
                        label={"购买"}
                        name={"goodsExpiryTime"}
                        style={{marginTop: 0, marginBottom: 0}}
                      >
                        <Input name={"goodsExpiryTime"} addonAfter={"天内"} style={{width: 180}} placeholder={"请输入天数"} />
                      </Form.Item>
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item> : ""}

              {goodsType == 2 ? <Form.Item
                label={"可核销次数"}
                name={"goodsVerificationTimes"}
                rules={[{ required: true, message: "可核销次数必填" }]}
              >
                <Input style={{width: 380}} placeholder={"请输入可核销次数"} />
              </Form.Item> : "" }

              <div
                style={{
                  padding: 10,
                  backgroundColor: "#f8f8f8",
                  fontSize: 14,
                  fontWeight: 700,
                }}
              >
                其他信息
              </div>


              <Form.Item
                    label={"是否可使用积分抵扣"}
                    name={"goodsPointsUse"}
                    rules={[{ required: true, message: "是否可使用积分抵扣必选" }]}
                    style={{ textAlign:'left' }}
                >
                    <Radio.Group onChange={changeGoodsPointsUse}>
                        <Radio defaultChecked value={true}>是</Radio>
                        <Radio value={false}>否</Radio>
                    </Radio.Group>
                </Form.Item>

                {goodsPointsUse == true ?

                 <Form.Item
                               
                                        label={"最多可抵用积分"}
                                        name={"goodsPointsLimit"}
                                        rules={[{ required: true, message: "最多可抵用积分必填" }]}
                                    >
                                        <Input style={{ width: 380 }} defaultValue={0} placeholder={"请输入最多可抵用积分"} />
                                    </Form.Item>
                :""}
                

                <Form.Item
              label={"虚拟销量"}
              name={"goodsVirtualSalesNum"}
              rules={[{ required: true, message: "虚拟销量必填" }]}
            >
              <Input style={{ width: 380 }} defaultValue={0} placeholder={"请输入虚拟销量"} />
            </Form.Item>

              <Form.Item
                label={"开售时间"}
                name={"goodsShow"}
                rules={[{ required: true, message: "开售时间必选" }]}
                style={{ marginTop: 24, textAlign: "left" }}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio value={1}>立即开售</Radio>
                    <Radio value={2}>
                      定时开售
                      <Form.Item noStyle={true} name={"goodsShowStartTimeStr"}>
                        <DatePicker
                          onChange={handleSelectGoodsShowStartTime}
                          showTime
                          style={{ marginLeft: 10 }}
                          placeholder={"请选择开售时间"}
                        />
                      </Form.Item>
                    </Radio>
                    <Radio value={0}>放入仓库</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label={"商品限购"}
                name={"goodsPurchaseLimits"}
                rules={[{ required: true, message: "商品限购必选" }]}
              >
                <Radio.Group onChange={changeGoodsPurchaseLimits}>
                  <Radio value={false}>否</Radio>
                  <Radio value={true}>是</Radio>
                </Radio.Group>
              </Form.Item>

              {goodsPurchaseLimits == true ? <Form.Item
                label={"限购类型"}
                name={"goodsPurchaseLimitsType"}
                rules={[{ required: true, message: "限购类型必选" }]}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio defaultChecked value={1}>
                      <Form.Item
                        colon={false}
                        label={"终身限购"}
                        name={"goodsPurchaseLimitsForeverTimes"}
                        style={{marginTop: 0, marginBottom: 0}}
                      >
                        <Input name={"goodsPurchaseLimitsForeverTimes"} addonAfter={"件"} style={{width: 180}} placeholder={"请输入件数"} />
                      </Form.Item>
                    </Radio>
                    <Radio value={2}>
                      <Form.Item
                        colon={false}
                        label={"按周期限购"}
                        name={"goodsPurchaseLimitsCycleType"}
                        style={{marginTop: 0, marginBottom: 0}}
                      >
                        <Select
                          onClick={(e)=>{e.preventDefault();e.stopPropagation();}}
                          showSearch
                          placeholder="请选择周期"
                          optionFilterProp="children"
                          style={{textAlign:'left'}}
                        >
                          <Option value={1} key={1}>每天</Option>
                          <Option value={2} key={2}>每周</Option>
                          <Option value={3} key={3}>每月</Option>
                        </Select>
                      </Form.Item>
                    </Radio>
                    <Form.Item
                      colon={false}
                      label={"限购"}
                      name={"goodsPurchaseLimitsCycleTimes"}
                      style={{marginTop: 8, marginBottom: 0}}
                    >
                      <Input name={"goodsPurchaseLimitsCycleTimes"} addonAfter={"件"} style={{width: 180}} placeholder={"请输入件数"} />
                    </Form.Item>
                  </Space>
                </Radio.Group>
              </Form.Item> : ""}

              <Form.Item
                    label={"是否可购买"}
                    name={"goodsPurchase"}
                    rules={[{ required: true, message: "是否可购买必选" }]}
                    style={{ textAlign:'left' }}
                >
                    <Radio.Group>
                        <Radio defaultChecked value={true}>是</Radio>
                        <Radio value={false}>否</Radio> 
                    </Radio.Group>
                </Form.Item>
                
              <Form.Item
                label={"商品排序"}
                name={"goodsSort"}
                rules={[{ required: true, message: "商品排序必填" }]}
              >
                <Input style={{ width: 380 }} placeholder={"请输入商品排序"} />
              </Form.Item>

              <Form.Item
                label={"商品所属门店"}
                name={"storeId"}
                rules={[{ required: true, message: "所属门店必选" }]}
              >
                <Select
                  showSearch
                  placeholder="请选择商品所属门店"
                  optionFilterProp="children"
                  style={{ textAlign: "left", width: 380 }}
                  disabled
                >
                  <Option value={0}>请选择</Option>
                  {optionsStore}
                </Select>
              </Form.Item>
            </TabPane>

            <TabPane tab="编辑商品详情" key="2">
              <Form.Item
                label={"商品详情"}
                name={"goodsContent"}
                style={{ marginTop: 24 }}
              >
                <BraftEditor
                  style={{ border: "1px solid #f0f0f0" }}
                  onChange={handleEditorChange}
                  media={{ uploadFn: myUploadFn }}
                />
              </Form.Item>
            </TabPane>
          </Tabs>
          <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
            <Button disabled loading={submitLoading} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Spin>
      </Form>
    </div>
  );
};
