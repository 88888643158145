import { useMutation, useQuery, useQueryClient } from "react-query";
import { RoomClass } from "../interfaces/room-class";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useRoomClassList = (param?: Partial<RoomClass>) => {
    const client = useHttp();
    const result = useQuery<any>(['roomClassPage', param], () => client('roomClass', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditRoomClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<RoomClass>) => client(`roomClass`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('roomClassPage')
        }
    )
}

export const useAddRoomClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<RoomClass>) => client(`roomClass`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('roomClassPage')
        }
    )
}

export const useDeleteRoomClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ roomClassId }: { roomClassId: number}) =>
            client(`roomClass/${roomClassId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('roomClassPage')
        }
    );
};

export const useRoomClass = (roomClassId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['roomClass', {roomClassId}],
        () => client(`roomClass/${roomClassId}`),
        {
            enabled: Boolean(roomClassId)
        }
    )
}