import { useMutation, useQuery, useQueryClient } from "react-query";
import { RoomTypeClass } from "../interfaces/room-type-class";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useRoomTypeClassList = (param?: Partial<RoomTypeClass>) => {
    const client = useHttp();
    const result = useQuery<any>(['roomTypeClassPage', param], () => client('roomTypeClass', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}
export const useRoomTypeClassListAll = () => {
    const client = useHttp();
    const result = useQuery<any>(['roomTypeClassListAll'], () => client('roomTypeClass/listAll'))
    const data = result.data?.data;
    return { ...result, data };
}


export const useEditRoomTypeClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<RoomTypeClass>) => client(`roomTypeClass`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('roomTypeClassPage')
    }
    )
}

export const useAddRoomTypeClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<RoomTypeClass>) => client(`roomTypeClass`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('roomTypeClassPage')
    }
    )
}

export const useDeleteRoomTypeClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ roomTypeClassId }: { roomTypeClassId: number }) =>
            client(`roomTypeClass/${roomTypeClassId}`, {
                method: "DELETE",
            }), {
        onSuccess: () => queryClient.invalidateQueries('roomTypeClassPage')
    }
    );
};

export const useRoomTypeClass = (roomTypeClassId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['roomTypeClass', { roomTypeClassId }],
        () => client(`roomTypeClass/${roomTypeClassId}`),
        {
            enabled: Boolean(roomTypeClassId)
        }
    )
}