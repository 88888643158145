import { Button, Col, message, Modal, Row, Space, Table, Image, Card, Form, Input } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { ErrorBox } from "../../components/lib"
import { useRoomTypeFacilitiesList, useDeleteRoomTypeFacilities } from "../../services/room-type-facilities-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useRoomTypeFacilitiesSearchParams } from "./util"
import { getShopId } from "../../utils/auth-provider"

export const RoomTypeFacilitiesListPage = () => {
  useDocumentTitle('房型设施列表', true)
  const imgUrl = process.env.REACT_APP_IMG_URL;
  const [param, setParam] = useRoomTypeFacilitiesSearchParams()
  const { isLoading, error, totalElements, data: list } = useRoomTypeFacilitiesList(useDebounce(param, 200))
  const shopId = getShopId();

  //分页
  const paginationProps = {
    showSizeChanger: true,
    onShowSizeChange: (current: number, pageSize: number) => {
      setParam({
        ...param,
        pageNum: current - 1,
        pageSize,
      });
    },
    defaultCurrent: 1,
    pageSize: param.pageSize,
    total: totalElements,
    onChange: (current: number, pageSize: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
        pageSize,
      }),
  };
  //搜索
  const onFinish = (values: any) => {
    setParam({
      ...param,
      roomTypeFacilitiesName: values.roomTypeFacilitiesName,
      pageNum: null,
    });
  };
  //删除事件
  const { mutateAsync } = useDeleteRoomTypeFacilities();
  const confirmDeleteRoomTypeFacilities = (roomTypeFacilitiesId: number) => {
    Modal.confirm({
      title: "确定删除这条数据吗?",
      content: "点击确定删除",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          mutateAsync({ roomTypeFacilitiesId }).then((data) => {
            if (data.status === 0) {
              message.info('删除成功');
              Modal.destroyAll();
            } else {
              message.error(data.msg)
            }
          });
        }).catch(
          () => console.log('Oops errors!')
        );
      },
      cancelText: "取消"
    });
  };
  //分页
  return (
    <div>
      <Row style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Link to={"/goods/room-manage/room-type-facilities/add"}>
            <Button type="primary">新建</Button>
          </Link>
        </Col>
      </Row>
      <Card style={{ backgroundColor: "#f2f3f5", marginBottom: 24 }}>
        <Form onFinish={onFinish}>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name="roomTypeFacilitiesName" label="房型设施名称">
                <Input placeholder="请输入房型设施名称" allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              style={{ marginLeft: 70 }}
              type="primary"
              htmlType="submit"
            >
              提交
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Table
        loading={isLoading}
        rowKey={columns => columns.roomTypeFacilitiesId}
        pagination={paginationProps}
        scroll={{ x: 1500 }}
        dataSource={list || []}
        columns={[
          {
            title: 'ID',
            dataIndex: 'roomTypeFacilitiesId',
            key: 'roomTypeFacilitiesId',
            align: 'center',
            width: 100,
          },

          {
            title: '设施名称',
            dataIndex: 'roomTypeFacilitiesName',
            key: 'roomTypeFacilitiesName',
            width: 150,
            align: 'center',
          },

          {
            title: '图片',
            dataIndex: 'roomTypeFacilitiesPic',
            key: 'roomTypeFacilitiesPic',
            align: 'center',
            width: 100,
            render: (value) => (
              <Image width={80} src={imgUrl + (shopId ? shopId : "1") + "/" + value} />
            ),
          },

          {
            title: '是否显示',
            dataIndex: 'roomTypeFacilitiesShow',
            key: 'roomTypeFacilitiesShow',
            align: 'center',
            width: 100,
            render: (value) => (
              <span>{value ? '显示' : '不显示'}</span>
            ),
          },

          {
            title: '排序',
            dataIndex: 'roomTypeFacilitiesSort',
            key: 'roomTypeFacilitiesSort',
            width: 150,
            align: 'center',
          },

          {
            title: '门店id',
            dataIndex: 'storeId',
            key: 'storeId',
            width: 150,
            align: 'center',
          },

          {
            title: '商户id',
            dataIndex: 'shopId',
            key: 'shopId',
            width: 150,
            align: 'center',
          },

          {
            title: '操作',
            key: 'action',
            align: 'center',
            width: 200,
            fixed: "right",
            render: (value, roomTypeFacilities) => (
              <Space size="middle">
                <Link to={"/goods/room-manage/room-type-facilities/update/" + roomTypeFacilities.roomTypeFacilitiesId}>
                  编辑
                </Link>
                <a onClick={() => confirmDeleteRoomTypeFacilities(roomTypeFacilities.roomTypeFacilitiesId)}>
                  删除
                </a>
              </Space>
            )
          }
        ]} />
      <ErrorBox error={error} />
    </div>
  )
}