import { Upload, message, Form } from 'antd';
import { PlusOutlined } from "@ant-design/icons";
import { getShopId, getToken } from '../utils/auth-provider';

const apiUrl = process.env.REACT_APP_API_URL;
const token = getToken();
const shopId = getShopId();

const UploadMorePic = (props: any) => {

    let { label, name, index, style, fileList, setFileList } = props;

    const uploadMoreButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
    );

    const handleChangeMore = (info: any, nowIndex: any) => {
        if (info.file.status === "uploading") {
            return;
        }
        if (info.file.status === "done") {
            let newFileList = fileList
            console.log("newFileList",newFileList);
            newFileList?.forEach((item: any, i: number) => {
                if (i === nowIndex) {
                    newFileList[i].push(info?.file)
                }
            })
            setFileList(newFileList)
        }
        if (info.file.status === "removed") {
            let newFileList = fileList
            console.log("newFileList",newFileList);
            newFileList?.forEach((item: any, i: number) => {
                if (i === nowIndex) {
                    newFileList[i] = [];
                    newFileList[i].push(info?.file)
                }
            })
            setFileList(newFileList)
        }
    };

    const beforeUploadMore = (file: any) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("只支持jpg或png格式的图片!");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("图片大小不能超过2MB!");
        }
        return isJpgOrPng && isLt2M;
    };

    const normMoreFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    return (
        <Form.Item
            name={name}
            label={label}
            valuePropName="fileList"
            getValueFromEvent={normMoreFile}
            style={style}
        >
            <Upload
                key={index}
                name={"imageFile"}
                multiple={true}
                action={apiUrl + "/upload/antdImg?index=" + index}
                listType="picture-card"
                fileList={fileList[index]}
                accept='image/png, image/jpeg'
                beforeUpload={beforeUploadMore}
                onChange={e => handleChangeMore(e, index)}
                headers={{ Authorization: "Bearer " + token }}
            >
                {uploadMoreButton}
            </Upload>
        </Form.Item>
    )

}

export default UploadMorePic;

