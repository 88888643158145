import { useMutation, useQuery, useQueryClient } from "react-query";
import { StoreFacilities } from "../interfaces/store-facilities";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useStoreFacilitiesList = (param?: Partial<StoreFacilities>) => {
    const client = useHttp();
    const result = useQuery<any>(['storeFacilitiesList', param], () => client('storeFacilities', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditStoreFacilities = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StoreFacilities>) => client(`storeFacilities`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeFacilitiesList')
        }
    )
}

export const useAddStoreFacilities = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StoreFacilities>) => client(`storeFacilities`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeFacilitiesList')
        }
    )
}

export const useDeleteStoreFacilities = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ storeFacilitiesId }: { storeFacilitiesId: number}) =>
            client(`storeFacilities/${storeFacilitiesId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeFacilitiesList')
        }
    );
};

export const useStoreFacilities = (storeFacilitiesId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['storeFacilities', {storeFacilitiesId}],
        () => client(`storeFacilities/${storeFacilitiesId}`),
        {
            enabled: Boolean(storeFacilitiesId)
        }
    )
}