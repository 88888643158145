import { Upload, message, Form, UploadProps, Progress } from 'antd';
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { getShopId, getToken } from '../utils/auth-provider';
import { useState } from 'react';

const apiUrl = process.env.REACT_APP_API_URL;
const videoUrl = process.env.REACT_APP_IMG_URL;
const token = getToken();
const shopId = getShopId();

const UploadVideo = (props: any) => {

    let { name, label, action, video, setVideo, style, required } = props;
    if (required === undefined) { required = false; }
    if (action === undefined) { action = 'add'; }

    const [uploading, setUploading] = useState(false);
    const [uploadPercent, setUploadPercent] = useState(0);
    const uploadVideoButton = (
        <div>
            {uploading ? (<>
                {/* <LoadingOutlined /> */}
                <Progress percent={uploadPercent} showInfo={false}
                    strokeColor={"#2491fc"}

                />
            </>) : <PlusOutlined />

            }

            <div style={{ marginTop: 8 }}>上传视频</div>
        </div>
    );
    let initialValue: any[] = [];
    if (action === 'update' || video) {
        initialValue = [
            {
                "uid": "",
                "lastModified": "",
                "lastModifiedDate": "",
                "name": "",
                "size": "",
                "type": "",
                "percent": "",
                "originFileObj": {
                    "uid": ""
                },
                "status": "done",
                "response": {
                    "status": 0,
                    "data": {
                        "relativePath": "",
                        "realPath": ""
                    }
                },
                "xhr": {}
            }
        ]
    }
    console.log("initialValue", initialValue);

    const beforeUpload = (file: any) => {
        setVideo("");
        setUploading(true);
        // const isLt50M = file.size / 1024 / 1024 < 50;
        // if (!isLt50M) {
        //     setUploading(false);
        //     message.error("视频大小不能超过50MB!");
        // }
        return true;
    };

    const handleChange = (info: any) => {
        console.log(info);
        const event = info.event
        if (event) { // 一定要加判断，不然会报错
            let percent = event.percent;
            setUploadPercent(percent);
        }
        if (info.file.status === "uploading") {
            return;
        }
        if (info.file.status === "done") {
            setUploading(false);
            const relativePath = info?.file?.response?.data?.relativePath;
            // console.log("返回值:",relativePath);
            setVideo(relativePath);
        }
        console.log("shopId", shopId);

    };

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    return (
        <Form.Item
            name={name}
            label={label}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            style={style}
            initialValue={initialValue}
            rules={[{ required: required, message: label + "必传" }]}
        >
            <Upload
                accept='video/*'
                name={"videoFile"}
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={apiUrl + "/upload/video"}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                headers={{ Authorization: "Bearer " + token }}
            >
                {video ? (
                    <video
                        src={videoUrl + (shopId ? shopId : "1") + "/" + video}
                        style={{ width: "100%", height: "100%" }}
                    />
                ) : (
                    uploadVideoButton
                )}
            </Upload>
        </Form.Item>
    )
}

export default UploadVideo;