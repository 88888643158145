import {
  Table,
  Space,
  Row,
  Col,
  List,
  Typography,
  Image,
  Tabs,
  Card,
  Form,
  DatePicker,
  message,
  Select,
  Input,
  Button,
  Modal,
  Radio,
  Spin,
} from "antd";
import {
  useOrdersList,
  useOrdersMutation,
  useRemarkOrders,
  useOrdersStoreMutation
} from "../../services/orders-service";
import { useDebounce, useDocumentTitle } from "../../utils";
import { useOrdersSearchParams } from "./util";
import { ErrorBox } from "../../components/lib";
import { useOrdersGoodsListMutation } from "../../services/orders-goods-service";
import { OrdersGoods } from "../../interfaces/orders-goods";
import { useOrdersAddressMutation } from "../../services/orders-address-service";
import { useExpressList } from "../../services/express-service";
import { useAddOrdersParcel } from "../../services/orders-parcel-service";
import { useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import "../orders/list.css";
import { useAuth } from "../../context/auth-context";
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text } = Typography;

export const OrdersListPage = () => {
  useDocumentTitle("订单列表", true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const imgUrl = process.env.REACT_APP_IMG_URL;
  const data = useAuth();
  const shopId = data?.storeAccount?.shopId;
  //参数
  const [param, setParam] = useOrdersSearchParams();
  const {
    isLoading,
    error,
    totalElements,
    data: list,
  } = useOrdersList(useDebounce(param, 200));
  //分页
  const paginationProps = {
    defaultCurrent: 1,
    pageSize: 10,
    total: totalElements,
    onChange: (current: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
      }),
  };
  const rangeConfig = {
    rules: [{ type: "array" as const, message: "请选择时间!" }],
  };
  const [startOrdersCreateTime, setStartOrdersCreateTime] = useState("");
  const [endOrdersCreateTime, setEndOrdersCreateTime] = useState("");
  const handleSelectTime = (value: any, dateString: any) => {
    setStartOrdersCreateTime(dateString[0]);
    setEndOrdersCreateTime(dateString[1]);
  };
  const [typeId, setTypeId] = useState("");
  const handleTab = (key: any, event: any) => {
    setTypeId(key);
    setParam({
      ...param,
      ordersState: key,
      pageNum: null,
    });
  };
  const [op, setOp] = useState(1);
  function changeOpSubmit() {
    setOp(1);
  }
  function changeOpExport() {
    setOp(2);
  }
  //完成
  const onFinish = (values: any) => {
    if (op === 1) {
      setParam({
        ...param,
        ordersSn: values.ordersSn,
        startOrdersCreateTime: startOrdersCreateTime,
        endOrdersCreateTime: endOrdersCreateTime,
        ordersReceiverName: values.ordersReceiverName,
        ordersReceiverTel: values.ordersReceiverTel,
        ordersGoodsInfo: values.ordersGoodsInfo,
        ordersState: values.ordersState,
        ordersType: values.ordersType,
        typeId: typeId,
        pageNum: null,
      });
    } else if (op === 2) {
      let ordersSn = values.ordersSn;
      let ordersReceiverName = values.ordersReceiverName;
      let ordersReceiverTel = values.ordersReceiverTel;
      let ordersGoodsInfo = values.ordersGoodsInfo;
      let ordersState = values.ordersState;
      let ordersType = values.ordersType;
      if (ordersSn === undefined) {
        ordersSn = "";
      }
      if (ordersReceiverName === undefined) {
        ordersReceiverName = "";
      }
      if (ordersReceiverTel === undefined) {
        ordersReceiverTel = "";
      }
      if (ordersGoodsInfo === undefined) {
        ordersGoodsInfo = "";
      }
      if (ordersState === undefined) {
        ordersState = "";
      }
      if (ordersType === undefined) {
        ordersType = "";
      }
      Modal.confirm({
        title: "确定导出当前列表数据吗?",
        content: "点击确定导出",
        okText: "确定",
        onOk(): any {
          return new Promise((resolve, reject) => {
            window.location.href =
              apiUrl +
              `/exportOrders?ordersSn=${ordersSn}&ordersReceiverName=${ordersReceiverName}&startOrdersCreateTime=${startOrdersCreateTime}
              &endOrdersCreateTime=${endOrdersCreateTime}&ordersReceiverTel=${ordersReceiverTel}&ordersGoodsInfo=${ordersGoodsInfo}
              &ordersState=${ordersState}&ordersType=${ordersType}&typeId=${typeId}&shopId=${shopId}`;
            message.success("导出成功");
            Modal.destroyAll();
          }).catch(() => console.log("Oops errors!"));
        },
        cancelText: "取消",
      });
    }
  };
  //发货
  const [modalLoading, setModalLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [submitDeliveryLoading, setSubmitDeliveryLoading] = useState(false);
  //订单详情
  const [orders, setOrders] = useState<any>();
  const useMutateOrders = useOrdersMutation;
  const { mutateAsync: mutateAsyncOrders } = useMutateOrders();
  //商品列表
  const [ordersGoodsList, setOrdersGoodsList] = useState<any>();
  const useMutateOrdersGoodsList = useOrdersGoodsListMutation;
  const { mutateAsync: mutateAsyncOrdersGoodsList } =
    useMutateOrdersGoodsList();
  //地址详情
  const [ordersAddress, setOrdersAddress] = useState<any>();
  const useMutateOrdersAddress = useOrdersAddressMutation;
  const { mutateAsync: mutateAsyncOrdersAddress } = useMutateOrdersAddress();
  //门店地址
  const [ordersStore, setOrdersStore] = useState<any>();
  const useMutateOrdersStore = useOrdersStoreMutation;
  const { mutateAsync: mutateAsyncOrdersStore } = useMutateOrdersStore();
  //物流
  const { data: expressList } = useExpressList();
  const options = expressList?.map((item: any, value: any) => (
    <Option value={item.expressId} key={item.expressId}>
      {item.expressName}
    </Option>
  ));
  //订单
  const [ordersId, setOrdersId] = useState<number>(0);
  //配送方式
  const [ordersDeliveryType, setOrdersDeliveryType] = useState<number>(1);
  //发货方式
  const [ordersParcelType, setOrdersParcelType] = useState(1);
  const showModal = (ordersId: number, ordersDeliveryType: number) => {
    setOrdersId(ordersId);
    setOrdersDeliveryType(ordersDeliveryType);
    if (ordersDeliveryType === 1) {
      setOrdersParcelType(1);
    }
    if (ordersDeliveryType === 2) {
      setOrdersParcelType(3);
    }
    setModalLoading(true);
    setIsModalVisible(true);
    //调用订单
    mutateAsyncOrders({ ordersId }).then((data: any) => {
      setOrders(data?.data);
    });
    //调用商品
    mutateAsyncOrdersGoodsList({ ordersId }).then((data: any) => {
      setOrdersGoodsList(data?.data);
      setModalLoading(false);
    });
    //调用地址
    mutateAsyncOrdersAddress({ ordersId }).then((data: any) => {
      setOrdersAddress(data.data);
    });
    //调用门店
    mutateAsyncOrdersStore({ ordersId }).then((data: any) => {
      setOrdersStore(data.data)
    });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  //选择发货方式
  const onChangeOrdersParcelType = (e: any) => {
    setOrdersParcelType(e.target.value);
  };
  //全选
  const [ordersGoodsIds, setOrdersGoodsIds] = useState<any>([]);
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: OrdersGoods[]) => {
      setOrdersGoodsIds(selectedRowKeys);
    },
    getCheckboxProps: (record: OrdersGoods) => ({
      disabled: record.canDelivery === false,
    }),
  };
  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
    "checkbox"
  );
  //物流公司选择
  const [expressId, setExpressId] = useState(0);
  function handleChangeExpress(value: any) {
    setExpressId(value);
  }
  //快递单号选择
  const [trackingNumber, setTrackingNumber] = useState("");
  function handleChangeTrackingNumber(e: any) {
    setTrackingNumber(e.target.value);
  }
  //取货码
  const [ordersPickSn, setOrdersPickSn] = useState("");
  function handleChangeOrdersPickSn(e: any) {
    setOrdersPickSn(e.target.value);
  }
  //发货
  const useMutateAddOrdersParcel = useAddOrdersParcel;
  const { mutateAsync: mutateAsyncAddOrdersParcel } =
    useMutateAddOrdersParcel();
  const handleOk = () => {
    if (ordersParcelType === 1) {
      if (ordersGoodsIds.length === 0) {
        message.error("请至少选择一件商品");
      }
      if (expressId === 0) {
        message.error("请选择快递公司");
      }
      if (trackingNumber.length === 0) {
        message.error("请输入真实有效的物流单号");
      }
      if (
        ordersGoodsIds.length > 0 &&
        expressId > 0 &&
        trackingNumber.length > 0
      ) {
        setSubmitDeliveryLoading(true);
        mutateAsyncAddOrdersParcel({
          ordersId,
          ordersGoodsIds,
          expressId,
          trackingNumber,
          ordersParcelType,
        }).then(() => {
          setIsModalVisible(false);
          setSubmitDeliveryLoading(false);
        });
      }
    } else if (ordersParcelType === 3) {
      if (ordersGoodsIds.length === 0) {
        message.error("请至少选择一件商品");
      }
      if (ordersPickSn.length === 0) {
        message.error("请输入取货码");
      }
      if (ordersGoodsIds.length > 0 && ordersPickSn.length > 0) {
        setSubmitDeliveryLoading(true);
        mutateAsyncAddOrdersParcel({
          ordersId,
          ordersGoodsIds,
          expressId,
          ordersPickSn,
          ordersParcelType,
        }).then((res) => {
          if (res.status === 1) {
            message.error(res.msg);
            setSubmitDeliveryLoading(false);
          } else {
            setIsModalVisible(false);
            setSubmitDeliveryLoading(false);
          }
        });
      }
    } else {
      if (ordersGoodsIds.length === 0) {
        message.error("请至少选择一件商品");
      } else {
        //开始提交
        setSubmitDeliveryLoading(true);
        mutateAsyncAddOrdersParcel({
          ordersId,
          ordersGoodsIds,
          ordersParcelType,
        }).then(() => {
          setIsModalVisible(false);
          setSubmitDeliveryLoading(false);
        });
      }
    }
  };
  //备注
  const [remarkModalLoading, setRemarkModalLoading] = useState(false);
  const [isRemarkModalVisible, setIsRemarkModalVisible] = useState(false);
  const [submitRemarkLoading, setSubmitRemarkLoading] = useState(false);
  //查询之前的备注
  const [ordersRemark, setOrdersRemark] = useState("");
  //最新的备注内容
  function handleChangeOrdersRemark(e: any) {
    setOrdersRemark(e.target.value);
  }
  //显示之前的备注
  const showRemarkModal = (ordersId: number) => {
    setRemarkModalLoading(true);
    setIsRemarkModalVisible(true);
    mutateAsyncOrders({ ordersId }).then((data) => {
      setOrdersId(data?.data?.ordersId);
      setOrdersRemark(data?.data?.ordersRemark);
      setRemarkModalLoading(false);
    });
  };
  //提交备注
  const useMutateRemarkOrders = useRemarkOrders;
  const { mutateAsync: mutateAsyncRemarkOrders } = useMutateRemarkOrders();
  const handleOkRemarkModal = () => {
    setSubmitRemarkLoading(true);
    mutateAsyncRemarkOrders({ ordersRemark, ordersId }).then(() => {
      setIsRemarkModalVisible(false);
      setSubmitRemarkLoading(false);
    });
  };
  //关闭弹窗
  const handleCancelRemarkModal = () => {
    setIsRemarkModalVisible(false);
  };
  return (
    <div>
      <Modal
        confirmLoading={submitRemarkLoading}
        centered
        bodyStyle={{ height: "100", overflowY: "auto" }}
        destroyOnClose={true}
        width={888}
        title="订单备注"
        visible={isRemarkModalVisible}
        onOk={handleOkRemarkModal}
        onCancel={handleCancelRemarkModal}
      >
        <Spin spinning={remarkModalLoading}>
          <Row style={{ marginBottom: 24 }}>
            <Form.Item label={"备注信息"}>
              <Input
                onChange={handleChangeOrdersRemark}
                style={{ width: 500 }}
                value={ordersRemark}
                placeholder={"请输入备注信息"}
              />
            </Form.Item>
          </Row>
        </Spin>
      </Modal>
      <Modal
        confirmLoading={submitDeliveryLoading}
        centered
        bodyStyle={{ height: "100", overflowY: "auto" }}
        destroyOnClose={true}
        width={888}
        title="订单发货"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Spin spinning={modalLoading}>
          <Row style={{ marginBottom: 24 }}>
            <Table
              pagination={false}
              style={{ width: "100%" }}
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              rowKey={(columns) => columns.ordersGoodsId}
              columns={[
                {
                  title: "商品",
                  dataIndex: "goodsName",
                  key: "goodsName",
                  width: "40%",
                  render: (value, item: any) => (
                    <Space size="middle">
                      <Row>
                        <Col span={6}>
                          <Image
                            width="100%"
                            src={imgUrl + item?.shopId + "/" + item?.goodsPic}
                          />
                        </Col>
                        <Col span={18} style={{ paddingLeft: 10 }}>
                          <Row>
                            <Col span={24} style={{ marginBottom: 5 }}>
                              {item?.goodsName}
                            </Col>
                            <Col span={24} style={{ marginBottom: 5 }}>
                              规格：{item?.goodsSkuInfo}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Space>
                  ),
                },
                {
                  title: "数量",
                  dataIndex: "goodsNum",
                  key: "goodsNum",
                  width: "20%",
                },
                {
                  title: "状态",
                  dataIndex: "goodsState",
                  key: "goodsState",
                  width: "20%",
                  render(goodsState) {
                    return (
                      <span>{goodsState === 30 ? "已发货" : "未发货"}</span>
                    );
                  },
                },
                {
                  title: "运单号",
                  dataIndex: "trackingNumber",
                  key: "trackingNumber",
                  width: "20%",
                },
              ]}
              dataSource={ordersGoodsList || []}
            />
          </Row>
          <Row style={{ marginBottom: 24 }}>
            <Col style={{ marginRight: 30 }}>配送信息</Col>
            <Col>
              {ordersDeliveryType === 1 ? (
                <Space direction="vertical">
                  <Text>配送方式：快递</Text>
                  <Text>
                    收货人：{ordersAddress?.ordersAddressReceiverName}{" "}
                    {ordersAddress?.ordersAddressReceiverTel}
                  </Text>
                  <Text>
                    收货地址：{ordersAddress?.ordersAddressProvince}
                    {ordersAddress?.ordersAddressCity}
                    {ordersAddress?.ordersAddressDistrict}
                    {ordersAddress?.ordersAddressDetail}
                  </Text>
                </Space>
              ) : (
                ""
              )}
              {ordersDeliveryType === 2 ? (
                <Space direction="vertical">
                  <Text>配送方式：到店自提</Text>
                  <Text>
                    联系信息：{orders?.data?.ordersReceiverName} {orders?.data?.ordersReceiverTel}
                  </Text>
                  <Text>门店名称：{ordersStore?.storeName}</Text>
                  <Text>
                    门店地址：{ordersStore?.storeProvinceName}
                    {ordersStore?.storeCityName}
                    {ordersStore?.storeDistrictName}
                    {ordersStore?.storeAddress}
                  </Text>
                </Space>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row style={{ marginBottom: 24 }}>
            <Col style={{ marginRight: 30 }}>发货方式</Col>
            <Col>
              <Space direction="vertical">
                {ordersDeliveryType === 1 ? (
                  <Radio.Group
                    onChange={onChangeOrdersParcelType}
                    value={ordersParcelType}
                  >
                    <Radio value={1}>快递配送</Radio>
                    <Radio value={2}>无需物流</Radio>
                  </Radio.Group>
                ) : (
                  ""
                )}
                {ordersDeliveryType === 2 ? (
                  <Radio.Group
                    onChange={onChangeOrdersParcelType}
                    value={ordersParcelType}
                  >
                    <Radio value={3}>需要验证自提码</Radio>
                    <Radio value={4}>无需验证自提码</Radio>
                  </Radio.Group>
                ) : (
                  ""
                )}
              </Space>
            </Col>
          </Row>
          {ordersParcelType === 1 ? (
            <Row>
              <Col style={{ marginRight: 30, visibility: "hidden" }}>
                物流信息
              </Col>
              <Col>
                <Row style={{ marginBottom: 0 }}>
                  <Form.Item label={"物流公司"}>
                    <Select
                      onChange={handleChangeExpress}
                      showSearch
                      style={{ width: 200 }}
                      placeholder="请选择物流公司"
                      optionFilterProp="children"
                    >
                      {options}
                    </Select>
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item label={"物流单号"}>
                    <Input
                      onChange={handleChangeTrackingNumber}
                      style={{ width: 200 }}
                      placeholder={"输入真实有效的物流单号"}
                    />
                  </Form.Item>
                </Row>
              </Col>
            </Row>
          ) : (
            ""
          )}
          {ordersParcelType === 3 ? (
            <Row>
              <Col style={{ marginRight: 30, visibility: "hidden" }}>
                提货信息
              </Col>
              <Col>
                <Row>
                  <Form.Item label={"提货码"}>
                    <Input
                      onChange={handleChangeOrdersPickSn}
                      style={{ width: 200 }}
                      placeholder={"输入提货码"}
                    />
                  </Form.Item>
                </Row>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </Spin>
      </Modal>
      <Card style={{ backgroundColor: "#f2f3f5", marginBottom: 24 }}>
        <Form onFinish={onFinish}>
          <Form.Item name="goodsAddTimeRange" label="下单时间" {...rangeConfig}>
            <RangePicker
              onChange={handleSelectTime}
              showTime
              format="YYYY-MM-DD HH:mm:ss"
            />
          </Form.Item>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name="ordersSn" label="订单编号">
                <Input placeholder="请输入订单编号" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="ordersReceiverName" label="收货人姓名">
                <Input placeholder="请输入收货人姓名" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="ordersReceiverTel" label="收货人手机号">
                <Input placeholder="请输入收货人手机号" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name="ordersGoodsInfo" label="商品名称">
                <Input placeholder="请输入商品名称" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="ordersState" label="订单状态">
                <Select
                  showSearch
                  placeholder="请选择订单类型"
                  optionFilterProp="children"
                  defaultValue={param.ordersState}
                >
                  <Option value={100}>全部</Option>
                  <Option value={10}>待付款</Option>
                  <Option value={20}>待发货</Option>
                  <Option value={30}>待收货</Option>
                  <Option value={40}>已完成</Option>
                  <Option value={0}>已取消</Option>
                  <Option value={50}>售后中</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="ordersType" label="订单类型">
                <Select
                  showSearch
                  placeholder="请选择订单状态"
                  optionFilterProp="children"
                  defaultValue={param.ordersType}
                >
                  <Option value={0}>全部</Option>
                  <Option value={1}>普通订单</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              onClick={changeOpSubmit}
              style={{ marginLeft: 70 }}
              type="primary"
              htmlType="submit"
            >
              提交
            </Button>
            {/* <Button
              onClick={changeOpExport}
              style={{ marginLeft: 20 }}
              type="primary"
              htmlType="submit"
            >
              导出
            </Button> */}
          </Form.Item>
        </Form>
      </Card>
      <Tabs
        onTabClick={handleTab}
        defaultActiveKey={param.typeId}
        type="card"
        size={"small"}
      >
        <TabPane tab="全部" key="100"></TabPane>
        <TabPane tab="待付款" key="10"></TabPane>
        <TabPane tab="待发货" key="20"></TabPane>
        <TabPane tab="待收货" key="30"></TabPane>
        <TabPane tab="已完成" key="40"></TabPane>
        <TabPane tab="已取消" key="0"></TabPane>
        <TabPane tab="售后中" key="50"></TabPane>
      </Tabs>
      <Row
        style={{
          height: 50,
          lineHeight: 50,
          fontWeight: 500,
          backgroundColor: "#f7f8fa",
        }}
      >
        <Col
          style={{
            height: 50,
            lineHeight: "50px",
            width: "25%",
            textAlign: "left",
            paddingLeft: 15,
          }}
        >
          商品
        </Col>
        <Col
          style={{
            height: 50,
            lineHeight: "50px",
            width: "15%",
            textAlign: "right",
            paddingRight: 15,
          }}
        >
          单价(元) / 数量
        </Col>
        <Col
          style={{
            height: 50,
            lineHeight: "50px",
            width: "10%",
            textAlign: "center",
          }}
        >
          售后
        </Col>
        <Col
          style={{
            height: 50,
            lineHeight: "50px",
            width: "10%",
            textAlign: "center",
          }}
        >
          买家 / 收货人
        </Col>
        <Col
          style={{
            height: 50,
            lineHeight: "50px",
            width: "10%",
            textAlign: "center",
          }}
        >
          配送方式
        </Col>
        <Col
          style={{
            height: 50,
            lineHeight: "50px",
            width: "10%",
            textAlign: "center",
          }}
        >
          实收金额(元)
        </Col>
        <Col
          style={{
            height: 50,
            lineHeight: "50px",
            width: "10%",
            textAlign: "center",
          }}
        >
          订单状态
        </Col>
        <Col
          style={{
            height: 50,
            lineHeight: "50px",
            width: "10%",
            textAlign: "center",
          }}
        >
          操作
        </Col>
      </Row>
      <List
        loading={isLoading}
        pagination={paginationProps}
        dataSource={list || []}
        renderItem={(item: any) => (
          <List.Item
            style={{ display: "block", borderBottom: "0px solid #f0f0f0" }}
          >
            <Table
              bordered={true}
              showHeader={false}
              pagination={false}
              dataSource={item.ordersGoodsList}
              title={() => (
                <Row>
                  <Col span={20}>
                    <span style={{ marginRight: 20 }}>
                      订单号：{item.ordersSn}
                    </span>
                    <span style={{ marginRight: 20 }}>
                      下单时间：
                      {item.ordersCreateTime
                        ? dayjs(item.ordersCreateTime).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        : "无"}
                    </span>
                    {item.ordersPaymentId ? (
                      <span style={{ marginRight: 20 }}>
                        支付方式：{item.ordersPaymentName}
                      </span>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col
                    style={{ textAlign: "right", paddingRight: 20 }}
                    span={4}
                  >
                    <span>
                      <Link to={"/orders/manage/detail/" + item.ordersId}>
                        查看详情
                      </Link>
                    </span>
                    <span style={{ marginLeft: 5, marginRight: 5 }}>-</span>
                    <span onClick={() => showRemarkModal(item?.ordersId)}>
                      备注
                    </span>
                  </Col>
                </Row>
              )}
              columns={[
                {
                  title: "商品",
                  dataIndex: "goodsName",
                  key: "goodsName",
                  width: "40%",
                  render: (value, ordersGoods) => (
                    <Space size="middle">
                      <Row>
                        <Col style={{ width: "10%" }}>
                          <Image
                            width="100%"
                            src={
                              imgUrl +
                              ordersGoods.shopId +
                              "/" +
                              ordersGoods.goodsPic
                            }
                          />
                        </Col>
                        <Col style={{ paddingLeft: 10, width: "60%" }}>
                          {ordersGoods.goodsName}
                        </Col>
                        <Col style={{ width: "30%", textAlign: "right" }}>
                          {ordersGoods.goodsPrice}
                          <br />
                          {ordersGoods.goodsNum}件
                        </Col>
                      </Row>
                    </Space>
                  ),
                },
                {
                  title: "售后",
                  dataIndex: "ordersRefund",
                  key: "ordersRefund",
                  width: "10%",
                  align: "center",
                  render: (value, ordersGoods) => (
                    <Space size="middle">
                      <Row>
                        {ordersGoods.ordersGoodsRefundState === 0
                          ? "无售后"
                          : ""}
                        {ordersGoods.ordersGoodsRefundState === 10
                          ? "退款申请待商家处理"
                          : ""}
                        {ordersGoods.ordersGoodsRefundState === 20
                          ? "商家不同意退款申请，待买家处理"
                          : ""}
                        {ordersGoods.ordersGoodsRefundState === 30
                          ? "商家已同意退款申请，待买家发货"
                          : ""}
                        {ordersGoods.ordersGoodsRefundState === 40
                          ? "买家已退货，待商家确认收货"
                          : ""}
                        {ordersGoods.ordersGoodsRefundState === 50
                          ? "商家拒绝收货，待买家处理"
                          : ""}
                        {ordersGoods.ordersGoodsRefundState === 60
                          ? "退款关闭"
                          : ""}
                        {ordersGoods.ordersGoodsRefundState === 70
                          ? "退款成功"
                          : ""}
                      </Row>
                    </Space>
                  ),
                },
                {
                  title: "买家 / 收货人",
                  dataIndex: "ordersReceiverName",
                  key: "ordersReceiverName",
                  width: "10%",
                  align: "center",
                  render: (text: any, row: any, index: any) => {
                    if (index === 0) {
                      return {
                        children: (
                          <span>
                            {item.memberNickname} <br />{" "}
                            {item.ordersReceiverName} <br />{" "}
                            {item.ordersReceiverTel}
                          </span>
                        ),
                        props: {
                          rowSpan: item.goodsNum,
                        },
                      };
                    }
                    if (index > 0) {
                      return {
                        props: {
                          rowSpan: 0,
                        },
                      };
                    }
                  },
                },
                {
                  title: "配送方式",
                  dataIndex: "ordersDeliveryType",
                  key: "ordersDeliveryType",
                  width: "10%",
                  align: "center",
                  render: (text: any, row: any, index: any) => {
                    if (index === 0) {
                      return {
                        children: (
                          <span>
                            {item.ordersDeliveryType === 1 ? "快递" : ""}
                            {item.ordersDeliveryType === 2 ? "到店自提" : ""}
                          </span>
                        ),
                        props: {
                          rowSpan: item.goodsNum,
                        },
                      };
                    }
                    if (index > 0) {
                      return {
                        props: {
                          rowSpan: 0,
                        },
                      };
                    }
                  },
                },
                {
                  title: "实收金额(元)",
                  dataIndex: "ordersActuallyAmount",
                  key: "ordersActuallyAmount",
                  width: "10%",
                  align: "center",
                  render: (text: any, row: any, index: any) => {
                    if (index === 0) {
                      return {
                        children: <span>{item.ordersActuallyAmount}</span>,
                        props: {
                          rowSpan: item.goodsNum,
                        },
                      };
                    }
                    if (index > 0) {
                      return {
                        props: {
                          rowSpan: 0,
                        },
                      };
                    }
                  },
                },
                {
                  title: "订单状态",
                  dataIndex: "ordersState",
                  key: "ordersState",
                  width: "10%",
                  align: "center",
                  render: (text: any, row: any, index: any) => {
                    if (index === 0) {
                      return {
                        children: <span>{item.ordersStateValue}</span>,
                        props: {
                          rowSpan: item.goodsNum,
                        },
                      };
                    }
                    if (index > 0) {
                      return {
                        props: {
                          rowSpan: 0,
                        },
                      };
                    }
                  },
                },
                {
                  title: "操作",
                  dataIndex: "action",
                  key: "action",
                  width: "10%",
                  align: "center",
                  render: (text: any, row: any, index: any) => {
                    if (index === 0) {
                      return {
                        children: (
                          <span>
                            {(item.ordersState === 20 || item.ordersState === 30) ? (
                              <Space size="middle">
                                <Button
                                  onClick={() =>
                                    showModal(
                                      item?.ordersId,
                                      item?.ordersDeliveryType
                                    )
                                  }
                                >
                                  发货
                                </Button>
                              </Space>
                            ) : (
                              ""
                            )}
                          </span>
                        ),
                        props: {
                          rowSpan: item.goodsNum,
                        },
                      };
                    }
                    if (index > 0) {
                      return {
                        children: (
                          <span>
                            {(item.ordersState === 20 || item.ordersState === 30) ? (
                              <Space size="middle">
                                <Button
                                  onClick={() =>
                                    showModal(
                                      item?.ordersId,
                                      item?.ordersDeliveryType
                                    )
                                  }
                                >
                                  发货
                                </Button>
                              </Space>
                            ) : (
                              ""
                            )}
                          </span>
                        ),
                        props: {
                          rowSpan: 0,
                        },
                      };
                    }
                  },
                },
              ]}
            />
            {item.ordersMessage ? (
              <div
                style={{
                  background: "#fdeeee",
                  color: "#da2626",
                  fontSize: 14,
                  padding: "5px 10px",
                  border: "1px solid #ebedf0",
                  borderTop: "0px solid #ebedf0",
                }}
              >
                买家备注：{item.ordersMessage}
              </div>
            ) : (
              ""
            )}
            {item.ordersRemark ? (
              <div
                style={{
                  background: "#fffaeb",
                  color: "#f90",
                  fontSize: 14,
                  padding: "5px 10px",
                  border: "1px solid #ebedf0",
                  borderTop: "0px solid #ebedf0",
                }}
              >
                卖家备注：{item.ordersRemark}
              </div>
            ) : (
              ""
            )}
          </List.Item>
        )}
      />
      <ErrorBox error={error} />
    </div>
  );
};
