import { Button, Col, message, Modal, Row, Space, Table, Image, Breadcrumb } from "antd"
import { useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useStoreFloorGoodsList, useDeleteStoreFloorGoods } from "../../services/store-floor-goods-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useStoreFloorGoodsSearchParams } from "./util"
import dayjs from 'dayjs';

export const StoreFloorGoodsListPage = () => {
    useDocumentTitle('楼层-已选商品列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const { pathname } = useLocation();
    const storeFloorId = pathname.match(/store\/floor\/goods\/list\/(\d+)/)?.[1]
    const [param, setParam] = useStoreFloorGoodsSearchParams()
    useEffect(() => {
        setParam({
            storeFloorId: storeFloorId,
        })
    }, [storeFloorId]);
    const {isLoading, error, totalElements, data: list} = useStoreFloorGoodsList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //删除事件
    const useMutateDeleteStoreFloorGoods = useDeleteStoreFloorGoods;
    const { mutateAsync } = useMutateDeleteStoreFloorGoods();
    const confirmDeleteStoreFloorGoods = (goodsId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ 
                        goodsId: goodsId,
                        storeFloorId: Number(storeFloorId)
                    }).then(() => {
                        message.info('删除成功');
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div>
            <Breadcrumb style={{ marginBottom: 24 }}>
                <Breadcrumb.Item>
                    <Link to={'/store/floor/list'}>楼层列表</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>已选商品列表</Breadcrumb.Item>
            </Breadcrumb>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/store/floor/goods/listNo/" + storeFloorId}>
                        <Button type="primary">未选商品列表</Button>
                    </Link>
                </Col>
            </Row>
            <Table 
                loading = { isLoading }
                pagination = { paginationProps } 
                rowKey={ columns => columns.goodsId } 
                columns = {[
                {
                    title: '商品名称',
                    width: 300,
                    dataIndex: 'goodsName',
                    key: 'goodsName',
                    fixed: 'left',
                    render: (value, goods) => (
                        <Space size="middle">
                            <Row>
                                <Col span={6}>
                                    <Image
                                        width="100%"
                                        src={imgUrl + goods.shopId + "/" + goods.goodsPic}
                                    />
                                </Col>
                                <Col span={18} style={{paddingLeft:10}}>
                                    {goods.goodsName}
                                </Col>
                            </Row>
                        </Space>
                    ),
                },
                {
                    title: '价格（元）',
                    dataIndex: 'goodsPrice',
                    key: 'goodsPrice',
                },
                { 
                    title: '访问量', 
                    dataIndex: 'goodsPageView', 
                    key: 'goodsPageView' 
                },
                { 
                    title: '库存', 
                    dataIndex: 'goodsStorage', 
                    key: 'goodsStorage' 
                },
                { 
                    title: '销量', 
                    dataIndex: 'goodsSaleNum', 
                    key: 'goodsSaleNum' 
                },
                { 
                    title: '创建时间', 
                    dataIndex: 'goodsAddTime', 
                    key: 'goodsAddTime',
                    render(value, goods) {
                        return <span>
                            {goods.goodsAddTime ? dayjs(goods.goodsAddTime).format('YYYY-MM-DD HH:mm:ss'):'无'}
                        </span>
                    },
                },
                {
                    title: '操作',
                    key: 'action',
                    render: (value, goods) => (
                        <Space size="middle">
                            <a onClick={() => confirmDeleteStoreFloorGoods(goods.goodsId)}>
                                删除
                            </a>
                        </Space>
                    ),
                    width: 100,
                }
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}