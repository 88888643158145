import { Button, Col, Menu, message, Modal, Row, Space, Table } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { ErrorBox } from "../../components/lib";
import { useStoreFacilitiesList, useDeleteStoreFacilities } from "../../services/store-facilities-service";
import { useDebounce, useDocumentTitle } from "../../utils";
import { useStoreFacilitiesSearchParams } from "./util";

export const StoreFacilitiesListPage = () => {
  useDocumentTitle("设备列表", true);
  const { pathname } = useLocation();
  // const storeId = pathname.match(/store\/facilities\/list\/(\d+)/)?.[1];
  const [param, setParam] = useStoreFacilitiesSearchParams();
  // useEffect(() => {
  //   setParam({
  //     storeId: storeId,
  //   });
  // }, [storeId]);
  const { isLoading, error, totalElements, data: list } = useStoreFacilitiesList(useDebounce(param, 200));
  //分页
  const paginationProps = {
    defaultCurrent: 1,
    pageSize: 10,
    total: totalElements,
    onChange: (current: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
      }),
  };
  //删除事件
  const useMutateDeleteStoreFacilities = useDeleteStoreFacilities;
  const { mutateAsync } = useMutateDeleteStoreFacilities();
  const confirmDeleteStoreFacilities = (storeFacilitiesId: number) => {
    Modal.confirm({
      title: "确定删除这条数据吗?",
      content: "点击确定删除",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          mutateAsync({ storeFacilitiesId }).then(() => {
            message.info("删除成功");
            Modal.destroyAll();
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };
  //分页
  return (
    <div>
      <Row style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Link to={"/store/facilities/add"}>
            <Button type="primary">新建设备</Button>
          </Link>
        </Col>
      </Row>
      <Table
        loading={isLoading}
        rowKey={(columns) => columns.storeFacilitiesId}
        pagination={paginationProps}
        columns={[
          {
            title: "设备ID",
            dataIndex: "storeFacilitiesId",
            key: "storeFacilitiesId",
            width: 100,
          },
          {
            title: "设备名称",
            dataIndex: "storeFacilitiesName",
            key: "storeFacilitiesName",
          },
          {
            title: "操作",
            key: "action",
            render: (value, storeFacilities) => (
              <Space size="middle">
                <Link to={"/store/facilities/update/" + storeFacilities.storeFacilitiesId}>
                  编辑
                </Link>
                <a onClick={() => confirmDeleteStoreFacilities(storeFacilities.storeFacilitiesId)}>
                  删除
                </a>
              </Space>
            ),
            width: 150,
          },
        ]}
        dataSource={list || []}
      />
      <ErrorBox error={error} />
    </div>
  );
};
