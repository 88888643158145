import {
  Button,
  Col,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Tabs,
  Upload,
  Typography,
  Card,
  Space,
  DatePicker,
  Checkbox,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAddGoods } from "../../services/goods-service";
import { useGoodsClassListTree } from "../../services/goods-class-service";
import { useStoreGoodsClassListTree } from "../../services/store-goods-class-service";
import { useGoodsSpecListAll } from "../../services/goods-spec-service";
import { useGoodsSpecValueListAll } from "../../services/goods-spec-value-service";
import { useGoodsSkuCreateList } from "../../services/goods-sku-service";
import { useGoodsTagListAll } from "../../services/goods-tag-service";
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";
import { useDocumentTitle } from "../../utils";
import { getToken } from "../../utils/auth-provider";
const { Option } = Select;
const { TabPane } = Tabs;
const { Text } = Typography;

export const GoodsAddPage = () => {
  //标题
  useDocumentTitle("新建商品", true);
  //API地址
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = getToken();
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 18 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  //准备平台分类
  const { data: goodsClassList } = useGoodsClassListTree();
  const options = goodsClassList?.data?.map((item: any, value: any) => (
    <Option value={item.goodsClassId} key={item.goodsClassId}>
      {item.goodsClassName}
    </Option>
  ));
  //准备门店商品分类
  const { data: storeGoodsClassList } = useStoreGoodsClassListTree();
  const optionsStoreGoodsClass = storeGoodsClassList?.data?.map((item: any, value: any) => (
    <Option value={item.storeGoodsClassId} key={item.storeGoodsClassId}>
      {item.storeGoodsClassName}
    </Option>
  ));
  //商品标签
  const { data: goodsTagList } = useGoodsTagListAll();
  const optionsGoodsTag = goodsTagList?.map((item: any, value: any) => (
    <Option value={item.goodsTagId} key={item.goodsTagId}>
      {item.goodsTagName}
    </Option>
  ));
  //规格值
  const { data: goodsSpecList } = useGoodsSpecListAll();
  const goodsSpecOptions = goodsSpecList?.map((item: any, value: any) => (
    <Option value={item.goodsSpecId} key={item.goodsSpecId}>
      {item.goodsSpecName}
    </Option>
  ));
  //表单
  const [submitLoading, setSubmitLoading] = useState(false);
  const [form] = useForm();
  //实物或虚拟商品
  const [goodsType, setGoodsType] = useState(1);
  function changeGoodsType(e: any) {
    setGoodsType(e.target.value);
  }
  //上传商品主图
  const [uploadLoading, setUploadLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const uploadButton = (
    <div>
      {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  const beforeUpload = (file: any) => {
    setImageUrl("");
    setUploadLoading(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setUploadLoading(false);
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setUploadLoading(false);
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setUploadLoading(false);
      setImageUrl(info?.file?.response?.data?.realPath);
      const relativePath = info?.file?.response?.data?.relativePath;
      form.setFieldsValue({
        goodsPic: relativePath,
      });
    }
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //上传多图
  const [fileList, setFileList] = useState([]);
  const uploadMoreButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  const handleChangeMore = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setFileList(info?.fileList);
    }
    if (info.file.status === "removed") {
      setFileList(info?.fileList);
    }
  };
  const beforeUploadMore = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const normMoreFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //上传视频
  const [uploadVideoLoading, setUploadVideoLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const uploadVideoButton = (
    <div>
      {uploadVideoLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传视频</div>
    </div>
  );
  const beforeVideoUpload = (file: any) => {
    setVideoUrl("");
    setUploadVideoLoading(true);
    const isLt20M = file.size / 1024 / 1024 < 20;
    if (!isLt20M) {
      setUploadVideoLoading(false);
      message.error("Image must smaller than 20MB!");
    }
    return isLt20M;
  };
  const handleVideoChange = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setUploadVideoLoading(false);
      setVideoUrl(info?.file?.response?.data?.realPath);
      const relativePath = info?.file?.response?.data?.relativePath;
      form.setFieldsValue({
        goodsVideo: relativePath,
      });
    }
  };
  const normVideoFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //上传视频封面
  const [uploadLoadings, setUploadLoadings] = useState(false);
  const [imageUrls, setImageUrls] = useState("");
  const uploadButtons = (
    <div>
      {uploadLoadings ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  const beforeUploads = (file: any) => {
    setImageUrls("");
    setUploadLoadings(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setUploadLoadings(false);
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setUploadLoadings(false);
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChanges = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setUploadLoadings(false);
      setImageUrls(info?.file?.response?.data?.realPath);
      const relativePath = info?.file?.response?.data?.relativePath;
      form.setFieldsValue({
        goodsVideoCoverPreviewPic: relativePath,
      });
    }
  };
  const normFiles = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

   //商品是否可使用
   const [goodsPointsUse, setGoodsPointsUse] = useState(false);
   function changeGoodsPointsUse(e: any){
       setGoodsPointsUse(e.target.value)
   }
   
  //多规格
  const [goodsSkuBOList, setGoodsSkuBOList] = useState();
  //执行查询规格值 / useGoodsSpecValueList
  const useMutateGoodsSpecValueList = useGoodsSpecValueListAll;
  const { mutateAsync: mutateAsyncGoodsSpecValueList } =
    useMutateGoodsSpecValueList();
  //goodsSpecIds
  const useMutateGoodsSkuCreateList = useGoodsSkuCreateList;
  const { mutateAsync: mutateAsyncGoodsSkuCreateList } =
    useMutateGoodsSkuCreateList();
  const [goodsSpecValueOptions1, setGoodsSpecValueOptions1] = useState();
  function changeGoodsSpecId1(value: any) {
    const goodsSpecId = value;
    mutateAsyncGoodsSpecValueList({ goodsSpecId }).then((data: any) => {
      const goodsSpecValueList = data.data;
      const goodsSpecValueOptions = goodsSpecValueList?.map(
        (item: any, value: any) => (
          <Option value={item.goodsSpecValueId} key={item.goodsSpecValueId}>
            {item.goodsSpecValueName}
          </Option>
        )
      );
      setGoodsSpecValueOptions1(goodsSpecValueOptions);
    });
  }
  const [goodsSpecValueOptions2, setGoodsSpecValueOptions2] = useState();
  function changeGoodsSpecId2(value: any) {
    const goodsSpecId = value;
    mutateAsyncGoodsSpecValueList({ goodsSpecId }).then((data: any) => {
      const goodsSpecValueList = data.data;
      const goodsSpecValueOptions = goodsSpecValueList?.map(
        (item: any, value: any) => (
          <Option value={item.goodsSpecValueId} key={item.goodsSpecValueId}>
            {item.goodsSpecValueName}
          </Option>
        )
      );
      setGoodsSpecValueOptions2(goodsSpecValueOptions);
    });
  }
  const [goodsSpecValueOptions3, setGoodsSpecValueOptions3] = useState();
  function changeGoodsSpecId3(value: any) {
    const goodsSpecId = value;
    mutateAsyncGoodsSpecValueList({ goodsSpecId }).then((data: any) => {
      const goodsSpecValueList = data.data;
      const goodsSpecValueOptions = goodsSpecValueList?.map(
        (item: any, value: any) => (
          <Option value={item.goodsSpecValueId} key={item.goodsSpecValueId}>
            {item.goodsSpecValueName}
          </Option>
        )
      );
      setGoodsSpecValueOptions3(goodsSpecValueOptions);
    });
  }
  const [goodsSpecValue1, setGoodsSpecValue1] = useState([]);
  const [goodsSpecValue2, setGoodsSpecValue2] = useState([]);
  const [goodsSpecValue3, setGoodsSpecValue3] = useState([]);
  function handleChange1(value: any) {
    setGoodsSpecValue1(value);
    mutateAsyncGoodsSkuCreateList([
      ...value,
      ...goodsSpecValue2,
      ...goodsSpecValue3,
    ]).then((data) => {
      setGoodsSkuBOList(data.data);
      form.setFieldsValue({ goodsSkuBOList: data.data });
    });
  }
  function handleChange2(value: any) {
    setGoodsSpecValue2(value);
    mutateAsyncGoodsSkuCreateList([
      ...goodsSpecValue1,
      ...value,
      ...goodsSpecValue3,
    ]).then((data) => {
      setGoodsSkuBOList(data.data);
      form.setFieldsValue({ goodsSkuBOList: data.data });
    });
  }
  function handleChange3(value: any) {
    setGoodsSpecValue3(value);
    mutateAsyncGoodsSkuCreateList([
      ...goodsSpecValue1,
      ...goodsSpecValue2,
      ...value,
    ]).then((data) => {
      setGoodsSkuBOList(data.data);
      form.setFieldsValue({ goodsSkuBOList: data.data });
    });
  }
  const [goodsSpecOpenShow, setGoodsSpecOpenShow] = useState(false);
  function changeGoodsSpecOpen(e: any) {
    setGoodsSpecOpenShow(e.target.value);
  }
  const [goodsSpecNum, setGoodsSpecNum] = useState(1);
  function changeGoodsSpecNum(e: any) {
    setGoodsSpecNum(goodsSpecNum + 1);
  }
  //配送方式
  const plainOptions = [
    { label: "快递发货", value: 1 },
    { label: "到店自提", value: 2 },
  ];
  const [goodsDeliveryTypeList, setGoodsDeliveryTypeList] = useState([]);
  const handleGoodsDeliveryTypeChange = (e: any) => {
    setGoodsDeliveryTypeList(e);
  };
  //上架时间
  const [goodsShowStartTimeStr, setGoodsShowStartTimeStr] = useState("");
  const handleSelectGoodsShowStartTime = (value: any, dateString: any) => {
    setGoodsShowStartTimeStr(dateString);
  };
  //富文本
  const [goodsContent, setGoodsContent] = useState("");
  const handleEditorChange = (editorState: any) => {
    setGoodsContent(editorState.toHTML());
  };
  const [controls, setControls] = useState<any>([
    "undo",
    "redo",
    "separator",
    "font-size",
    "line-height",
    "letter-spacing",
    "separator",
    "text-color",
    "bold",
    "italic",
    "underline",
    "strike-through",
    "separator",
    "superscript",
    "subscript",
    "remove-styles",
    "emoji",
    "separator",
    "text-indent",
    "text-align",
    "separator",
    "headings",
    "list-ul",
    "list-ol",
    "blockquote",
    "code",
    "separator",
    "link",
    "separator",
    "hr",
    "separator",
    "media",
    "separator",
    "clear",
  ]);
  //富文本上传
  const myUploadFn = (param: any) => {
    console.log(param);
    const serverURL = apiUrl + "/upload/braftEditorImag";
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    const successFn = (response: any) => {
      // 假设服务端直接返回文件上传后的地址
      // 上传成功后调用param.success并传入上传后的文件地址
      param.success({
        url: xhr.responseText,
        meta: {
          loop: true, // 指定音视频是否循环播放
          autoPlay: true, // 指定音视频是否自动播放
          controls: true, // 指定音视频是否显示控制栏
        },
      });
    };
    const progressFn = (event: any) => {
      // 上传进度发生变化时调用param.progress
      param.progress((event.loaded / event.total) * 100);
    };
    const errorFn = (response: any) => {
      // 上传发生错误时调用param.error
      param.error({
        msg: "unable to upload.",
      });
    };
    xhr.upload.addEventListener("progress", progressFn, false);
    xhr.addEventListener("load", successFn, false);
    xhr.addEventListener("error", errorFn, false);
    xhr.addEventListener("abort", errorFn, false);
    fd.append("file", param.file);
    xhr.open("POST", serverURL, true);
    xhr.setRequestHeader("name", "imageFile");
    xhr.setRequestHeader("Authorization", "Bearer " + token);
    xhr.send(fd);
  };
  //提交
  const useMutateAddGoods = useAddGoods;
  const { mutateAsync: mutateAsyncAddGoods, error } = useMutateAddGoods();
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    if (values.goodsShow == 2) {
      if (goodsShowStartTimeStr.length == 0) {
        setSubmitLoading(false);
        message.error("开售时间必选");
        return;
      }
    }
    if (values.goodsUniformFreight == true) {
      if (values.goodsFreight == null) {
        setSubmitLoading(false);
        message.error("请输入快递运费");
        return;
      }
    }
    var goodsMorePic = "";
    fileList?.map((item: any, value: any) => {
      goodsMorePic = goodsMorePic + item?.response?.data?.relativePath + ",";
    });
    const goodsSkuBOList = values?.goodsSkuBOList;
    mutateAsyncAddGoods({
      ...values,
      goodsDeliveryTypeList,
      goodsMorePic,
      goodsShowStartTimeStr,
      goodsContent,
      goodsSkuBOList,
    }).then(() => {
      window.location.href = "/#/goods/manage/list";
    });
  };
  return (
    <div>
      <Row style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Link to={"/goods/manage/list"}>
            <Button type="primary">商品列表</Button>
          </Link>
        </Col>
      </Row>
      <Form
        form={form}
        {...layout}
        name="basic"
        onFinish={onFinish}
        initialValues={{
          goodsSort: 0,
          goodsShow: 1,
          goodsType: 1,
          goodsSpecOpen: false,
          goodsUniformFreight: true,
          goodsDistributionChannel: ["1"],
        }}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="基本信息" key="1">
            <div
              style={{
                padding: 10,
                backgroundColor: "#f8f8f8",
                fontSize: 14,
                fontWeight: 700,
              }}
            >
              基本信息
            </div>
            <Form.Item
              label={"平台商品分类"}
              name={"goodsClassId"}
              rules={[{ required: true, message: "平台商品分类必选" }]}
              style={{marginTop: 24}}
            >
              <Select
                showSearch
                placeholder="请选择平台商品分类"
                optionFilterProp="children"
                style={{ textAlign: "left", width: 380 }}
              >
                {options}
              </Select>
            </Form.Item>

            <Form.Item
              label={"商品类型"}
              name={"goodsType"}
              rules={[{ required: true, message: "商品类型必选" }]}

            >
              <Radio.Group onChange={changeGoodsType}>
                <Radio defaultChecked value={1}>
                  实物商品（物流发货）
                </Radio>
                <Radio value={2}>虚拟商品（无需物流）</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label={"商品名称"}
              name={"goodsName"}
              rules={[{ required: true, message: "商品名称必填" }]}
            >
              <Input style={{ width: 380 }} placeholder={"请输入商品名称"} />
            </Form.Item>

            <Form.Item
              label={"商品卖点"}
              name={"goodsPromotions"}
              rules={[{ required: true, message: "商品卖点必填" }]}
            >
              <Input style={{ width: 380 }} placeholder={"请输入商品卖点"} />
            </Form.Item>

            <Form.Item label={"商品SPU"} name={"goodsSpu"}>
              <Input style={{ width: 380 }} placeholder={"请输入商品SPU"} />
            </Form.Item>

            <Form.Item
              label={"门店商品分类"}
              name={"storeGoodsClassId"}
              rules={[{ required: true, message: "门店商品分类必选" }]}
            >
              <Select
                showSearch
                placeholder="请选择门店商品分类"
                optionFilterProp="children"
                style={{ textAlign: "left", width: 380 }}
              >
                {optionsStoreGoodsClass}
              </Select>
            </Form.Item>

            <Form.Item label={"商品标签"} name={"goodsTagId"}>
              <Select
                showSearch
                placeholder="请选择商品标签"
                optionFilterProp="children"
                style={{ textAlign: "left", width: 380 }}
              >
                <Option value={0}>请选择</Option>
                {optionsGoodsTag}
              </Select>
            </Form.Item>

            {/* 商品主图 */}
            <Form.Item
              name={"uploadPic"}
              label={"商品主图"}
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[{ required: true, message: "商品主图必传" }]}
              style={{ textAlign: "left" }}
            >
              <Upload
                name="imageFile"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={apiUrl + "/upload/img"}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                headers={{ Authorization: "Bearer " + token }}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>

            <Form.Item
              hidden
              label={"商品主图"}
              name={"goodsPic"}
              rules={[{ required: true, message: "商品主图必传" }]}
            >
              <Input />
            </Form.Item>

            {/* 商品详情图 */}
            <Form.Item
              name={"uploadMore"}
              label={"商品详情图"}
              valuePropName="fileList"
              getValueFromEvent={normMoreFile}
              rules={[{ required: true, message: "商品详情图必传" }]}
              style={{ textAlign: "left" }}
            >
              <Upload
                name="imageFile"
                action={apiUrl + "/upload/img"}
                listType="picture-card"
                fileList={fileList as []}
                beforeUpload={beforeUploadMore}
                onChange={handleChangeMore}
                headers={{ Authorization: "Bearer " + token }}
              >
                {fileList.length < 5 ? uploadMoreButton : ""}
              </Upload>
            </Form.Item>

            <Form.Item
              name={"uploadVideo"}
              label={"商品视频"}
              valuePropName="fileList"
              getValueFromEvent={normVideoFile}
              style={{ textAlign: "left" }}
            >
              <Upload
                name="videoFile"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={apiUrl + "/upload/video"}
                beforeUpload={beforeVideoUpload}
                onChange={handleVideoChange}
                headers={{ Authorization: "Bearer " + token }}
              >
                {videoUrl ? (
                  <video src={videoUrl} style={{ width: "100%" }} />
                ) : (
                  uploadVideoButton
                )}
              </Upload>
            </Form.Item>

            <Form.Item hidden label={"商品视频"} name={"goodsVideo"}>
              <Input />
            </Form.Item>

            <Form.Item
              name={"uploadPics"}
              label={"视频贴片"}
              valuePropName="fileList"
              getValueFromEvent={normFiles}
              style={{ textAlign: "left" }}
            >
              <Upload
                name="imageFile"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={apiUrl + "/upload/img"}
                beforeUpload={beforeUploads}
                onChange={handleChanges}
                headers={{ Authorization: "Bearer " + token }}
              >
                {imageUrls ? (
                  <img src={imageUrls} alt="avatar" style={{ width: "100%" }} />
                ) : (
                  uploadButtons
                )}
              </Upload>
              <Text style={{ color: "#999", fontSize: 12 }}>
                视频贴片是指视频播放器在播放前展示的图片。
              </Text>
            </Form.Item>

            <Form.Item
              hidden
              label={"视频贴片"}
              name={"goodsVideoCoverPreviewPic"}
            >
              <Input />
            </Form.Item>

            <div
              style={{
                padding: 10,
                backgroundColor: "#f8f8f8",
                fontSize: 14,
                fontWeight: 700,
              }}
            >
              价格库存
            </div>

            <Form.Item
              label={"统一规格"}
              name={"goodsSpecOpen"}
              rules={[{ required: true, message: "统一规格必选" }]}
            >
              <Radio.Group onChange={changeGoodsSpecOpen}>
                <Radio defaultChecked value={false}>
                  是
                </Radio>
                <Radio value={true}>否</Radio>
              </Radio.Group>
            </Form.Item>

            {!goodsSpecOpenShow ? (
              <Form.Item
                label={"商品规格"}
                name={"goodsSpecName"}
                rules={[{ required: true, message: "商品规格必填" }]}
              >
                <Input style={{ width: 380 }} placeholder={"请输入商品规格"} />
              </Form.Item>
            ) : (
              ""
            )}

            {!goodsSpecOpenShow ? (
              <Form.Item
                label={"商品价格"}
                name={"goodsPrice"}
                rules={[{ required: true, message: "商品价格必填" }]}
              >
                <Input style={{ width: 380 }} placeholder={"请输入商品价格"} />
              </Form.Item>
            ) : (
              ""
            )}

            {!goodsSpecOpenShow ? (
              <Form.Item
                label={"商品采购价"}
                name={"goodsPurchasePrice"}
                rules={[{ required: true, message: "商品采购价必填" }]}
              >
                <Input
                  style={{ width: 380 }}
                  placeholder={"请输入商品采购价格"}
                />
              </Form.Item>
            ) : (
              ""
            )}

            {!goodsSpecOpenShow ? (
              <Form.Item
                label={"商品采购起购数"}
                name={"goodsPurchaseMinBuyNum"}
                rules={[{ required: true, message: "商品采购起购数必填" }]}
              >
                <Input
                  style={{ width: 380 }}
                  placeholder={"请输入商品采购起购数"}
                />
              </Form.Item>
            ) : (
              ""
            )}

            {!goodsSpecOpenShow ? (
              <Form.Item
                label={"商品划线价"}
                name={"goodsOriginalPrice"}
                rules={[{ required: true, message: "商品划线价必填" }]}
              >
                <Input
                  style={{ width: 380 }}
                  placeholder={"请输入商品划线价"}
                />
              </Form.Item>
            ) : (
              ""
            )}

            {!goodsSpecOpenShow ? (
              <Form.Item
                label={"商品成本价"}
                name={"goodsCostPrice"}
                rules={[{ required: true, message: "商品成本价必填" }]}
              >
                <Input
                  style={{ width: 380 }}
                  placeholder={"请输入商品成本价"}
                />
              </Form.Item>
            ) : (
              ""
            )}

            {!goodsSpecOpenShow ? (
              <Form.Item
                label={"商品库存"}
                name={"goodsStorage"}
                rules={[{ required: true, message: "商品库存必填" }]}
              >
                <Input style={{ width: 380 }} placeholder={"请输入商品库存"} />
              </Form.Item>
            ) : (
              ""
            )}

            {goodsSpecOpenShow ? (
              <Form.Item label={"商品规格"}>
                <Card
                  style={{
                    width: 900,
                    boxShadow: "0 0 4px 0 rgb(200 200 200 / 50%)",
                  }}
                >
                  {goodsSpecNum > 0 ? (
                    <div>
                      <Form.Item label={"规格名"} name={"goodsSpecId1"}>
                        <Select
                          showSearch
                          filterOption={false}
                          placeholder="请选择规格名"
                          optionFilterProp="children"
                          style={{ textAlign: "left", width: 380 }}
                          onChange={changeGoodsSpecId1}
                        >
                          {goodsSpecOptions}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={"规格值"}
                        name={"goodsSpecValueId1"}
                        style={{ marginBottom: 40 }}
                      >
                        <Select
                          mode="multiple"
                          showSearch
                          filterOption={false}
                          placeholder="请选择规格值"
                          optionFilterProp="children"
                          style={{ textAlign: "left", width: 380 }}
                          onChange={handleChange1}
                        >
                          {goodsSpecValueOptions1}
                        </Select>
                      </Form.Item>
                    </div>
                  ) : (
                    ""
                  )}

                  {goodsSpecNum > 1 ? (
                    <div>
                      <Form.Item label={"规格名"} name={"goodsSpecId2"}>
                        <Select
                          showSearch
                          filterOption={false}
                          placeholder="请选择商品规格"
                          optionFilterProp="children"
                          style={{ textAlign: "left", width: 380 }}
                          onChange={changeGoodsSpecId2}
                        >
                          {goodsSpecOptions}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={"规格值"}
                        name={"goodsSpecValueId2"}
                        style={{ marginBottom: 40 }}
                      >
                        <Select
                          mode="multiple"
                          showSearch
                          filterOption={false}
                          placeholder="请选择规格值"
                          optionFilterProp="children"
                          style={{ textAlign: "left", width: 380 }}
                          onChange={handleChange2}
                        >
                          {goodsSpecValueOptions2}
                        </Select>
                      </Form.Item>
                    </div>
                  ) : (
                    ""
                  )}

                  {goodsSpecNum > 2 ? (
                    <div>
                      <Form.Item label={"规格名"} name={"goodsSpecId3"}>
                        <Select
                          showSearch
                          filterOption={false}
                          placeholder="请选择商品规格"
                          optionFilterProp="children"
                          style={{ textAlign: "left", width: 380 }}
                          onChange={changeGoodsSpecId3}
                        >
                          {goodsSpecOptions}
                        </Select>
                      </Form.Item>
                      <Form.Item label={"规格值"} name={"goodsSpecValueId3"}>
                        <Select
                          filterOption={false}
                          mode="multiple"
                          showSearch
                          placeholder="请选择规格值"
                          optionFilterProp="children"
                          style={{ textAlign: "left", width: 380 }}
                          onChange={handleChange3}
                        >
                          {goodsSpecValueOptions3}
                        </Select>
                      </Form.Item>
                    </div>
                  ) : (
                    ""
                  )}
                  {goodsSpecNum < 3 ? (
                    <Button onClick={changeGoodsSpecNum}>新增规格</Button>
                  ) : (
                    ""
                  )}
                </Card>
              </Form.Item>
            ) : (
              ""
            )}

            {goodsSpecOpenShow == true ? (
              <Form.Item label={"规格明细"} name={"spec"}>
                <Card
                  style={{
                    width: 900,
                    boxShadow: "0 0 4px 0 rgb(200 200 200 / 50%)",
                  }}
                >
                  <Space
                    style={{ display: "flex", marginBottom: 0 }}
                    align="baseline"
                  >
                    <Form.Item
                      style={{
                        width: 10,
                        marginBottom: 10,
                        textAlign: "center",
                        display: "none",
                      }}
                    >
                      规格值ID
                    </Form.Item>
                    <Form.Item
                      style={{
                        width: 300,
                        marginBottom: 10,
                        textAlign: "center",
                      }}
                    >
                      规格名
                    </Form.Item>

                    <Form.Item
                      style={{
                        width: 80,
                        marginBottom: 10,
                        textAlign: "center",
                      }}
                    >
                      价格
                    </Form.Item>

                    <Form.Item
                      style={{
                        width: 80,
                        marginBottom: 10,
                        textAlign: "center",
                      }}
                    >
                      原价
                    </Form.Item>
                    <Form.Item
                      style={{
                        width: 80,
                        marginBottom: 10,
                        textAlign: "center",
                      }}
                    >
                      成本价
                    </Form.Item>
                    <Form.Item
                      style={{
                        width: 80,
                        marginBottom: 10,
                        textAlign: "center",
                      }}
                    >
                      库存
                    </Form.Item>
                  </Space>

                  <Form.List
                    initialValue={goodsSkuBOList}
                    name="goodsSkuBOList"
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <Space
                            key={field.key}
                            style={{ display: "flex", marginBottom: 8 }}
                            align="baseline"
                          >
                            <Space>
                              <Form.Item
                                name={[field.name, "goodsSpecValueIds"]}
                                rules={[
                                  { required: true, message: "规格值ID必填" },
                                ]}
                                style={{ display: "none" }}
                              >
                                <Input style={{ width: 10 }} />
                              </Form.Item>
                              <Form.Item
                                name={[field.name, "goodsSkuName"]}
                                rules={[
                                  { required: true, message: "规格名必填" },
                                ]}
                              >
                                <Input style={{ width: 300 }} />
                              </Form.Item>

                              <Form.Item
                                name={[field.name, "goodsSkuPrice"]}
                                rules={[
                                  { required: true, message: "规格价格必填" },
                                ]}
                              >
                                <Input style={{ width: 80 }} />
                              </Form.Item>

                              <Form.Item
                                name={[field.name, "goodsSkuOriginalPrice"]}
                                rules={[
                                  { required: true, message: "规格原价必填" },
                                ]}
                              >
                                <Input style={{ width: 80 }} />
                              </Form.Item>
                              <Form.Item
                                name={[field.name, "goodsSkuCostPrice"]}
                                rules={[
                                  { required: true, message: "规格成本价必填" },
                                ]}
                              >
                                <Input style={{ width: 80 }} />
                              </Form.Item>
                              <Form.Item
                                name={[field.name, "goodsSkuStorage"]}
                                rules={[
                                  { required: true, message: "规格库存必填" },
                                ]}
                              >
                                <Input style={{ width: 80 }} />
                              </Form.Item>
                            </Space>
                            {/* <MinusCircleOutlined onClick={() => remove(field.name)} /> */}
                          </Space>
                        ))}
                        {/* <Form.Item>
                                            <Button style={{width: 150}} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                添加规格
                                            </Button>
                                        </Form.Item> */}
                      </>
                    )}
                  </Form.List>
                </Card>
              </Form.Item>
            ) : (
              ""
            )}

            {goodsType == 1 ? (
              <div
                style={{
                  padding: 10,
                  backgroundColor: "#f8f8f8",
                  fontSize: 14,
                  fontWeight: 700,
                }}
              >
                物流信息
              </div>
            ) : (
              ""
            )}

            {goodsType == 1 ? (
              <Form.Item
                label={"配送方式"}
                name={"goodsDeliveryType"}
                rules={[{ required: true, message: "配送方式必选" }]}
                style={{ marginTop: 24 }}
              >
                <Checkbox.Group
                  onChange={handleGoodsDeliveryTypeChange}
                  options={plainOptions}
                />
              </Form.Item>
            ) : (
              ""
            )}

            {goodsType == 1 ? (
              <Form.Item
                label={"快递运费"}
                name={"goodsUniformFreight"}
                rules={[{ required: true, message: "快递运费必选" }]}
                style={{ textAlign: "left" }}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio defaultChecked value={true}>
                      <Form.Item
                        colon={false}
                        label={"统一邮费"}
                        name={"goodsFreight"}
                        style={{ marginTop: 0, marginBottom: 0 }}
                      >
                        <Input
                          name={"goodsFreight"}
                          prefix={"¥"}
                          style={{ width: 180 }}
                          placeholder={"请输入邮费价格"}
                        />
                      </Form.Item>
                    </Radio>
                    {/* <Radio value={false}>运费模版</Radio> */}
                  </Space>
                </Radio.Group>
              </Form.Item>
            ) : (
              ""
            )}

            <div
              style={{
                padding: 10,
                backgroundColor: "#f8f8f8",
                fontSize: 14,
                fontWeight: 700,
              }}
            >
              其他信息
            </div>

            <Form.Item
                    label={"是否可使用积分抵扣"}
                    name={"goodsPointsUse"}
                    rules={[{ required: true, message: "是否可使用积分抵扣必选" }]}
                    style={{ textAlign:'left' }}
                >
                    <Radio.Group onChange={changeGoodsPointsUse}>
                        <Radio defaultChecked value={true}>是</Radio>
                        <Radio value={false}>否</Radio>
                    </Radio.Group>
                </Form.Item>

                {goodsPointsUse == true ?

                 <Form.Item
                               
                                        label={"最多可抵用积分"}
                                        name={"goodsPointsLimit"}
                                        rules={[{ required: true, message: "最多可抵用积分必填" }]}
                                    >
                                        <Input style={{ width: 380 }} defaultValue={0} placeholder={"请输入最多可抵用积分"} />
                                    </Form.Item>
                :""}
                

                <Form.Item
              label={"虚拟销量"}
              name={"goodsVirtualSalesNum"}
              rules={[{ required: true, message: "虚拟销量必填" }]}
            >
              <Input style={{ width: 380 }} defaultValue={0} placeholder={"请输入虚拟销量"} />
            </Form.Item>

            <Form.Item
              label={"开售时间"}
              name={"goodsShow"}
              rules={[{ required: true, message: "开售时间必选" }]}
              style={{ marginTop: 24, textAlign: "left" }}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio defaultChecked value={1}>
                    立即开售
                  </Radio>
                  <Radio value={2}>
                    定时开售
                    <Form.Item noStyle={true} name={"goodsShowStartTimeStr"}>
                      <DatePicker
                        onChange={handleSelectGoodsShowStartTime}
                        showTime
                        style={{ marginLeft: 10 }}
                        placeholder={"请选择开售时间"}
                      />
                    </Form.Item>
                  </Radio>
                  <Radio value={0}>放入仓库</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label={"商品排序"}
              name={"goodsSort"}
              rules={[{ required: true, message: "商品排序必填" }]}
            >
              <Input style={{ width: 380 }} placeholder={"请输入商品排序"} />
            </Form.Item>
          </TabPane>

          <TabPane tab="商品详情" key="2">
            <Form.Item
              label={"商品详情"}
              name={"goodsContent"}
              style={{ marginTop: 24 }}
            >
              <BraftEditor
                style={{ border: "1px solid #f0f0f0" }}
                onChange={handleEditorChange}
                media={{ uploadFn: myUploadFn }}
              />
            </Form.Item>
          </TabPane>
        </Tabs>
        <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
          <Button loading={submitLoading} type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
