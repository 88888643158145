import {
    Button,
    Form,
    Input,
    message,
    Radio,
    Row,
    Select,
    Spin,
    Upload,
    Cascader,
    Tooltip,
    Typography,
    Tabs,
  } from "antd";
  import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
  import { useForm } from "antd/lib/form/Form";
  import { useEffect, useState } from "react";
  import { useStore, useEditStore } from "../../services/store-service";
  import { useStoreDetail } from "../../services/store-detail-service";
  import { useAreaListAll } from "../../services/area-service";
  import { useStoreClassListTree } from "../../services/store-class-service";
  import { useDocumentTitle } from "../../utils";
  import { getToken } from "../../utils/auth-provider";
  import BraftEditor from "braft-editor";
  import "braft-editor/dist/index.css";
  const { TabPane } = Tabs;
  const { Option } = Select;
  
  export const StoreUpdatePage = () => {
    //标题
    useDocumentTitle("编辑门店", true);
    //API地址
    const apiUrl = process.env.REACT_APP_API_URL;
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const token = getToken();
    //布局
    const layout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 7 },
    };
    const tailLayout = {
      wrapperCol: { offset: 3 },
    };
    //准备区域列表
    const { data: areaListAll } = useAreaListAll();
    //准备门店分类列表
    const { data: storeClassList } = useStoreClassListTree();
    const storeClassOption = storeClassList?.data?.map(
      (item: any, value: any) => (
        <Option value={item.storeClassId} key={item.storeClassId}>
          {item.storeClassName}
        </Option>
      )
    );
    //表单
    const [form] = useForm();
    //准备回显数据
    const { data: editingStore, isLoading } = useStore();
    useEffect(() => {
      const storePosition = [
        editingStore?.data.storeProvinceId,
        editingStore?.data.storeCityId,
        editingStore?.data.storeDistrictId,
      ];
      form.setFieldsValue({ ...editingStore?.data, storePosition });
      //logo
      const picUrl = imgUrl + editingStore?.data?.shopId + "/" + editingStore?.data?.storeLogo;
      if (editingStore?.data?.storeLogo != null) {
        setImageUrl(picUrl);
      }
      //banner
      const picUrls = imgUrl + editingStore?.data?.shopId + "/" + editingStore?.data?.storeBanner;
      if (editingStore?.data?.storeBanner != null) {
        setImageUrls(picUrls);
      }
    }, [editingStore, form]);
    //获取商品详情
    const [storeContent, setStoreContent] = useState("");
    const { data: editingStoreDetail } = useStoreDetail();
    useEffect(() => {
      setStoreContent(editingStoreDetail?.data?.storeContent);
      form.setFieldsValue({
        storeContent: BraftEditor.createEditorState(
          editingStoreDetail?.data?.storeContent
        ),
      });
    }, [editingStoreDetail, form]);
    //图片上传 - logo
    const [uploadLoading, setUploadLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const uploadButton = (
        <div>
          {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
    );
    const beforeUpload = (file:any) => {
        setImageUrl("");
        setUploadLoading(true);
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            setUploadLoading(false);
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            setUploadLoading(false);
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const handleChange = (info:any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            setUploadLoading(false);
            setImageUrl(info?.file?.response?.data?.realPath);
            const relativePath = info?.file?.response?.data?.relativePath
            form.setFieldsValue({
              storeLogo: relativePath
            })
        }
    };
    const normFile = (e: any) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };
    //图片上传 - banner
    const [uploadLoadings, setUploadLoadings] = useState(false);
    const [imageUrls, setImageUrls] = useState("");
    const uploadButtons = (
      <div>
        {uploadLoadings ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>上传图片</div>
      </div>
    );
    const beforeUploads = (file: any) => {
      setImageUrls("");
      setUploadLoadings(true);
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        setUploadLoadings(false);
        message.error("You can only upload JPG/PNG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        setUploadLoadings(false);
        message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    };
    const handleChanges = (info: any) => {
      if (info.file.status === "uploading") {
        return;
      }
      if (info.file.status === "done") {
        setUploadLoadings(false);
        setImageUrls(info?.file?.response?.data?.realPath);
        const relativePath = info?.file?.response?.data?.relativePath;
        form.setFieldsValue({
          storeBanner: relativePath,
        });
      }
    };
    const normFiles = (e: any) => {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };
    //富文本
    const handleEditorChange = (editorState: any) => {
      console.log(editorState.toHTML());
      setStoreContent(editorState.toHTML());
    };
    //富文本上传
    const myUploadFn = (param: any) => {
      console.log(param);
      const serverURL = apiUrl + "/upload/braftEditorImg";
      const xhr = new XMLHttpRequest();
      const fd = new FormData();
      const successFn = (response: any) => {
        // 假设服务端直接返回文件上传后的地址
        // 上传成功后调用param.success并传入上传后的文件地址
        param.success({
          url: xhr.responseText,
          meta: {
            loop: true, // 指定音视频是否循环播放
            autoPlay: true, // 指定音视频是否自动播放
            controls: true, // 指定音视频是否显示控制栏
          },
        });
      };
      const progressFn = (event: any) => {
        // 上传进度发生变化时调用param.progress
        param.progress((event.loaded / event.total) * 100);
      };
      const errorFn = (response: any) => {
        // 上传发生错误时调用param.error
        param.error({
          msg: "unable to upload.",
        });
      };
      xhr.upload.addEventListener("progress", progressFn, false);
      xhr.addEventListener("load", successFn, false);
      xhr.addEventListener("error", errorFn, false);
      xhr.addEventListener("abort", errorFn, false);
      fd.append("file", param.file);
      xhr.open("POST", serverURL, true);
      xhr.setRequestHeader("name", "imageFile");
      xhr.setRequestHeader("Authorization", "Bearer " + token);
      xhr.send(fd);
    };
    //编辑
    const useMutateEditStore = useEditStore;
    const { mutateAsync, error } = useMutateEditStore();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
      setSubmitLoading(true);
      mutateAsync({
        ...values,
        storeContent,
        storeProvinceName: values?.storePosition[0],
        storeCityName: values?.storePosition[1],
        storeDistrictName: values?.storePosition[2]
      }).then(() => {
        message.info("编辑成功");
        setSubmitLoading(false);
      });
    };
    return (
      <div>
        <Spin spinning={isLoading}>
          <Form form={form} {...layout} onFinish={onFinish}>
            <Tabs>
              <TabPane tab="编辑基本信息" key="1">
                <Form.Item
                  label={"门店名称"}
                  name={"storeName"}
                  style={{ marginTop: 24 }}
                  rules={[{ required: true, message: "门店名称必填" }]}
                >
                  <Input placeholder={"请输入门店名称"} />
                </Form.Item>
  
                <Form.Item
                  label={"门店电话"}
                  name={"storeTel"}
                  rules={[{ required: true, message: "门店电话必填" }]}
                >
                  <Input placeholder={"请输入门店电话"} />
                </Form.Item>
  
                <Form.Item
                  label={"人均消费"}
                  name={"storePerPerson"}
                  rules={[{ required: true, message: "人均消费必填" }]}
                >
                  <Input placeholder={"请输入人均消费"} />
                </Form.Item>

                <Form.Item
                  label={"平台抽点"}
                  name={"storeCommissionRatio"}
                  rules={[{ required: true, message: "平台抽点必填" }]}
                >
                  <Input disabled addonAfter={"%"} placeholder={"请输入平台抽点"} />
                </Form.Item>

                <Form.Item
                  name={"uploadPic"}
                  label={"门店Logo"}
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  style={{ textAlign: "left" }}
                >
                  <Upload
                    name="imageFile"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={apiUrl + "/upload/img"}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    headers={{ Authorization: "Bearer " + token }}
                  >
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />  : uploadButton}
                  </Upload>
                </Form.Item>
  
                <Form.Item
                  hidden
                  label={"门店Logo"}
                  name={"storeLogo"}
                  rules={[{ required: true, message: "门店Logo必传" }]}
                >
                  <Input />
                </Form.Item>
  
                <Form.Item
                  name={"uploadPics"}
                  label={"门店Banner"}
                  valuePropName="fileList"
                  getValueFromEvent={normFiles}
                  style={{ textAlign: "left" }}
                >
                  <Upload
                    name="imageFile"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={apiUrl + "/upload/img"}
                    beforeUpload={beforeUploads}
                    onChange={handleChanges}
                    headers={{ Authorization: "Bearer " + token }}
                  >
                    {imageUrls ? <img src={imageUrls} alt="avatar" style={{ width: "100%" }} />  : uploadButtons}
                  </Upload>
                </Form.Item>
  
                <Form.Item
                  hidden
                  label={"门店Banner"}
                  name={"storeBanner"}
                  rules={[{ required: true, message: "门店Banner必传" }]}
                >
                  <Input />
                </Form.Item>
  
                <Form.Item
                  label={"门店分类"}
                  name={"storeClassId"}
                  rules={[{ required: true, message: "所属门店分类必选" }]}
                >
                  <Select
                    showSearch
                    placeholder="请选择门店分类"
                    optionFilterProp="children"
                    style={{ textAlign: "left" }}
                  >
                    <Option value={0} key={0}>
                      请选择
                    </Option>
                    {storeClassOption}
                  </Select>
                </Form.Item>
  
                <Form.Item
                  label={"所在地区"}
                  name={"storePosition"}
                  rules={[{ required: true, message: "所在地区必选" }]}
                >
                  <Cascader
                    disabled={true}
                    options={areaListAll?.data}
                    placeholder="请选择所在地区"
                  />
                </Form.Item>
  
                <Form.Item
                  label={"详细地址"}
                  name={"storeAddress"}
                  rules={[{ required: true, message: "详细地址必填" }]}
                >
                  <Input placeholder={"请输入详细地址"} />
                </Form.Item>
  
                <Form.Item
                  label={"经纬度"}
                  name={"storeLongitude"}
                  style={{ marginBottom: 0 }}
                  rules={[{ required: true, message: "经纬度必填" }]}
                >
                  <Input.Group compact>
                    <Form.Item
                      style={{ marginRight: 5, width: 123 }}
                      name={"storeLongitude"}
                      rules={[{ required: true, message: "经度必填" }]}
                    >
                      <Input placeholder={"请输入经度"} />
                    </Form.Item>
                    <Form.Item
                      style={{ marginRight: 10, width: 123 }}
                      name={"storeLatitude"}
                      rules={[{ required: true, message: "纬度必填" }]}
                    >
                      <Input placeholder={"请输入纬度"} />
                    </Form.Item>
                    <Tooltip title="腾讯地图坐标拾起器">
                      <Typography.Link
                        target="_blank"
                        href="https://lbs.qq.com/getPoint/"
                      >
                        坐标拾起器
                      </Typography.Link>
                    </Tooltip>
                  </Input.Group>
                </Form.Item>
  
                <Form.Item
                  label={"是否营业"}
                  name={"storeBusiness"}
                  rules={[{ required: true, message: "是否营业必选" }]}
                  style={{ textAlign: "left" }}
                >
                  <Radio.Group>
                    <Radio value={true}>营业</Radio>
                    <Radio value={false}>不营业</Radio>
                  </Radio.Group>
                </Form.Item>
  
                <Form.Item
                  label={"营业开始时间"}
                  name={"storeBusinessStartHour"}
                  style={{ marginBottom: 0 }}
                  rules={[{ required: true, message: "营业开始时间必填" }]}
                >
                  <Input.Group compact>
                    <Form.Item
                      style={{ marginRight: 5, width: 123 }}
                      name={"storeBusinessStartHour"}
                      rules={[{ required: true, message: "时必填" }]}
                    >
                      <Input placeholder={"请输入时"} />
                    </Form.Item>
                    <Form.Item
                      style={{ marginRight: 10, width: 123 }}
                      name={"storeBusinessStartMinute"}
                      rules={[{ required: true, message: "分必填" }]}
                    >
                      <Input placeholder={"请输入分"} />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
  
                <Form.Item
                  label={"营业结束时间"}
                  name={"storeBusinessEndHour"}
                  style={{ marginBottom: 0 }}
                  rules={[{ required: true, message: "营业结束时间必填" }]}
                >
                  <Input.Group compact>
                    <Form.Item
                      style={{ marginRight: 5, width: 123 }}
                      name={"storeBusinessEndHour"}
                      rules={[{ required: true, message: "时必填" }]}
                    >
                      <Input placeholder={"请输入时"} />
                    </Form.Item>
                    <Form.Item
                      style={{ marginRight: 10, width: 123 }}
                      name={"storeBusinessEndMinute"}
                      rules={[{ required: true, message: "分必填" }]}
                    >
                      <Input placeholder={"请输入分"} />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
  
                <Form.Item
                  label={"门店排序"}
                  name={"storeSort"}
                  rules={[{ required: true, message: "门店排序必填" }]}
                >
                  <Input placeholder={"请输入门店排序"} />
                </Form.Item>
  
                <Form.Item
                  label={"是否显示"}
                  name={"storeShow"}
                  rules={[{ required: true, message: "门店显示必选" }]}
                  style={{ textAlign: "left" }}
                >
                  <Radio.Group>
                    <Radio value={true}>显示</Radio>
                    <Radio value={false}>不显示</Radio>
                  </Radio.Group>
                </Form.Item>
              </TabPane>
  
              <TabPane tab="编辑门店详情" key="2">
                <Form.Item
                  label={"门店详情"}
                  name={"storeContent"}
                  style={{ marginTop: 24 }}
                >
                  <BraftEditor
                    style={{ border: "1px solid #f0f0f0", width: "800px" }}
                    onChange={handleEditorChange}
                    media={{ uploadFn: myUploadFn }}
                  />
                </Form.Item>
              </TabPane>
            </Tabs>
  
            <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
              <Button loading={submitLoading} type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    );
  };
  