import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Menu,
  message,
  Row,
  Radio,
  Select,
  Upload,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAddStoreVideo } from "../../services/store-video-service";
import { useStoreVideoAlbumListAll } from "../../services/store-video-album-service";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useDocumentTitle } from "../../utils";
import { getToken } from "../../utils/auth-provider";
import UploadVideo from "../../components/upload-video";
const { Option } = Select;

export const StoreVideoAddPage = () => {
  //标题
  useDocumentTitle("新建视频", true);
  //API地址
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = getToken();
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  //准备专辑列表
  const { data: storeVideoAlbumList } = useStoreVideoAlbumListAll();
  console.log(storeVideoAlbumList);
  const options = storeVideoAlbumList?.data?.map((item: any, value: any) => (
    <Option value={item.storeVideoAlbumId} key={item.storeVideoAlbumId}>
      {item.storeVideoAlbumName}
    </Option>
  ));
  // 图片
  const [uploadLoading, setUploadLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const uploadButton = (
    <div>
      {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  const beforeUpload = (file: any) => {
    setImageUrl("");
    setUploadLoading(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setUploadLoading(false);
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = (file.size / 1024 / 1024) < 2;
    if (!isLt2M) {
      setUploadLoading(false);
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setUploadLoading(false);
      setImageUrl(info?.file?.response?.data?.realPath);
      const relativePath = info?.file?.response?.data?.relativePath;
      form.setFieldsValue({
        storeVideoCoverPreviewPic: relativePath,
      });
    }
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //完整音频
  const [uploadVideoLoading, setUploadVideoLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const uploadVideoButton = (
    <div>
      {uploadVideoLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传视频</div>
    </div>
  );
  const beforeVideoUpload = (file: any) => {
    setVideoUrl("");
    setUploadVideoLoading(true);
    const isLt20M = file.size / 1024 / 1024 < 20;
    if (!isLt20M) {
      setUploadVideoLoading(false);
      message.error("Image must smaller than 20MB!");
    }
    return isLt20M;
  };
  const handleVideoChange = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setUploadVideoLoading(false);
      setVideoUrl(info?.file?.response?.data?.realPath);
      const relativePath = info?.file?.response?.data?.relativePath;
      form.setFieldsValue({
        storeVideoUrl: relativePath,
      });
    }
  };
  const normVideoFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //表单
  const [form] = useForm();
  const [storeVideoUrl,setStoreVideoUrl] = useState();
  //新建
  const useMutateAddStoreVideo = useAddStoreVideo;
  const { mutateAsync, error } = useMutateAddStoreVideo();
  //提交表单
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({ ...values,storeVideoUrl }).then(() => {
      message.info("新建成功");
      window.location.href = "/#/store/video/list";
    });
  };
  return (
    <div>
      <Menu
        style={{ marginBottom: 24 }}
        mode="horizontal"
        selectedKeys={["storeVideo"]}
      >
        <Menu.Item key="storeVideo">
          <Link to={"/store/video/list"}>视频管理</Link>
        </Menu.Item>
        <Menu.Item key="searchDefault">
          <Link to={"/store/video/album/list"}>专辑管理</Link>
        </Menu.Item>
      </Menu>
      <Row>
        <Col span={12}>
          <Link to={"/store/video/list"}>
            <Button type="primary">视频列表</Button>
          </Link>
        </Col>
      </Row>
      <Divider style={{ marginBottom: 24 }} />
      <Form
        form={form}
        {...layout}
        onFinish={onFinish}
        initialValues={{
          storeVideoSort: 0,
          storeVideoShow: true,
        }}
      >
        <Form.Item
          label={"视频名称"}
          name={"storeVideoName"}
          rules={[{ required: true, message: "视频名称必填" }]}
        >
          <Input placeholder={"请输入视频名称"} />
        </Form.Item>

        <Form.Item
          label={"所属专辑"}
          name={"storeVideoAlbumId"}
          rules={[{ required: true, message: "所属专辑必选" }]}
        >
          <Form.Item name={"storeVideoAlbumId"} noStyle>
            <Select
              showSearch
              placeholder="请选择所属视频"
              optionFilterProp="children"
            >
              {/* <Option value={0} key={0}>
                请选择
              </Option> */}
              {options}
            </Select>
          </Form.Item>
        </Form.Item>

        <Form.Item
          name={"uploadPic"}
          label={"视频封面"}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: true, message: "视频封面必传" }]}
          style={{ textAlign: "left" }}
        >
          <Upload
            name="imageFile"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={apiUrl + "/upload/img"}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            headers={{ Authorization: "Bearer " + token }}
          >
            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: "100%" }} /> : uploadButton }
          </Upload>
        </Form.Item>

        <Form.Item
          hidden
          label={"视频封面"}
          name={"storeVideoCoverPreviewPic"}
          rules={[{ required: true, message: "视频封面必传" }]}
        >
          <Input />
        </Form.Item>
            
        <UploadVideo
          name={"uploadVideo"}
          label={"视频"}
          video={storeVideoUrl}
          setVideo={setStoreVideoUrl}
          required={true}
        ></UploadVideo>


        <Form.Item
          label={"视频排序"}
          name={"storeVideoSort"}
          rules={[{ required: true, message: "视频排序必填" }]}
        >
          <Input placeholder={"请输入视频排序"} />
        </Form.Item>

        <Form.Item
          label={"是否显示"}
          name={"storeVideoShow"}
          rules={[{ required: true, message: "视频显示必选" }]}
          style={{ textAlign: "left" }}
        >
          <Radio.Group>
            <Radio defaultChecked value={true}>
              显示
            </Radio>
            <Radio value={false}>不显示</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
          <Button loading={submitLoading} type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
