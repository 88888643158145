import { Button, Cascader, Col, Divider, Form, Input, message, Radio, Row, Select, Tooltip, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAddRoomType } from "../../services/room-type-service";
import { useDocumentTitle } from "../../utils"
import UploadPic from '../../components/upload-pic';
import { useAreaListAll } from "../../services/area-service";
import UploadMorePic from "../../components/upload-more-pic";
import { useRoomTypeClassListAll } from "../../services/room-type-class-service";
import { useRoomTypeTagListAll } from "../../services/room-type-tag-service";
import { useRoomTypeFacilitiesListAll } from "../../services/room-type-facilities-service";
export const RoomTypeAddPage = () => {
    //标题
    useDocumentTitle('新建房型', true)
    const navigate = useNavigate();

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //表单
    const [form] = useForm();
    const [roomTypePic, setRoomTypePic] = useState("");
    const [fileList, setFileList] = useState<any[]>([[]]);
    const [uploading, setUploading] = useState<boolean>(false);

    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useAddRoomType();
    const onFinish = (values: any) => {
        // setSubmitLoading(true);
        var roomTypeMorePicList = values?.uploadRoomTypeMorePic?.map((item: any) => {
            return item?.response;
        });
        console.log(values, roomTypeMorePicList);

        mutateAsync({
            ...values, roomTypePic, roomTypeMorePicList,
            provinceId: values?.storePosition[0],
            cityId: values?.storePosition[1],
            districtId: values?.storePosition[2]
        }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/goods/room-manage/room-type/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };
    const roomTypeSalesmanPromotionChange = (e: any) => {
        setRoomTypeSalesmanPromotion(e.target.value);
    }
    const [roomTypeSalesmanPromotion, setRoomTypeSalesmanPromotion] = useState(false);

    //准备区域列表
    const { data: areaListAll } = useAreaListAll();

    const { data: roomTypeClassListAll } = useRoomTypeClassListAll();
    const roomTypeClassListOptions = roomTypeClassListAll?.map((item: any) => {
        return (<Select.Option value={item.roomTypeClassId}>{item.roomTypeClassName}</Select.Option>)
    })

    const { data: roomTypeTagListAll } = useRoomTypeTagListAll();
    const roomTypeTagListOptions = roomTypeTagListAll?.map((item: any) => {
        return (<Select.Option value={item.roomTypeTagId}>{item.roomTypeTagName}</Select.Option>)
    })

    const { data: roomTypeFacilitiesListAll } = useRoomTypeFacilitiesListAll();
    const roomTypeFacilitiesListOptions = roomTypeFacilitiesListAll?.map((item: any) => {
        return (<Select.Option value={item.roomTypeFacilitiesId}>{item.roomTypeFacilitiesName}</Select.Option>)
    })
    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/goods/room-manage/room-type/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Form
                form={form}
                {...layout}
                onFinish={onFinish}
                initialValues={{
                    roomTypeSort: 0,
                    roomTypeShow: true,
                    roomTypeSalesmanPromotion: false,
                }}>
                <Form.Item
                    label={"房型分类"}
                    name={"roomTypeClassId"}
                    rules={[{ required: false, message: "房型分类必选" }]}
                >
                    <Select style={{ width: 380 }} placeholder={"请选择房型分类id"}  >
                        {roomTypeClassListOptions}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={"房型名称"}
                    name={"roomTypeName"}
                    rules={[{ required: true, message: "房型名称必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入房型名称"} />
                </Form.Item>

                <Form.Item
                    label={"房型促销语"}
                    name={"roomTypePromotions"}
                    rules={[{ required: false, message: "房型促销语必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入房型促销语"} />
                </Form.Item>

                <UploadPic
                    label={'图片'}
                    name={'uploadRoomTypePic'}
                    pic={roomTypePic}
                    setPic={setRoomTypePic}
                    action={'add'}
                    style={{ textAlign: 'left' }}
                >
                </UploadPic>

                <UploadMorePic
                    label={'多图'}
                    name={'uploadRoomTypeMorePic'}
                    fileList={fileList}
                    setFileList={setFileList}
                    index={0}
                >

                </UploadMorePic>

                <Form.Item
                    label={"房型标签"}
                    name={"roomTypeTagIdList"}
                    rules={[{ required: false, message: "房型标签必选" }]}
                >
                    <Select mode="multiple" style={{ width: 380 }} placeholder={"请选择房型标签"}  >
                        {roomTypeTagListOptions}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={"房型设备"}
                    name={"roomTypeFacilitiesIdList"}
                    rules={[{ required: false, message: "房型设备必选" }]}
                >
                    <Select mode="multiple" style={{ width: 380 }} placeholder={"请选择房型设备"}  >
                        {roomTypeFacilitiesListOptions}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={"房型价格"}
                    name={"roomTypePrice"}
                    rules={[{ required: true, message: "房型价格必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入房型价格"} />
                </Form.Item>

                <Form.Item
                    label={"房型面积"}
                    name={"roomTypeArea"}
                    rules={[{ required: false, message: "房型面积必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入房型面积"} />
                </Form.Item>

                <Form.Item
                    label={"最多入住人数"}
                    name={"roomTypePersonNum"}
                    rules={[{ required: true, message: "最多入住人数必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入最多入住人数"} />
                </Form.Item>

                <Form.Item
                    label={"房型备注"}
                    name={"roomTypeRemark"}
                    rules={[{ required: false, message: "房型备注必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入房型备注"} />
                </Form.Item>

                <Form.Item
                    label={"是否参与分销"}
                    name={"roomTypeSalesmanPromotion"}
                    rules={[{ required: true, message: "是否参与分销必选" }]}
                    style={{ textAlign: 'left' }}
                >
                    <Radio.Group onChange={roomTypeSalesmanPromotionChange}>
                        <Radio value={true}>是</Radio>
                        <Radio value={false}>否</Radio>
                    </Radio.Group>
                </Form.Item>
                {roomTypeSalesmanPromotion ?
                    <Form.Item
                        label={"佣金类型"}
                        name={"roomTypeSalesmanCommissionType"}
                        rules={[{ required: false, message: "佣金类型必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入佣金类型"} />
                    </Form.Item> : ""
                }


                <Form.Item
                    label={"所在地区"}
                    name={"storePosition"}
                    rules={[{ required: true, message: "所在地区必选" }]}
                >
                    <Cascader style={{ width: 380 }}
                        options={areaListAll?.data}
                        placeholder="请选择所在地区"
                    />
                </Form.Item>

                <Form.Item
                    label={"是否显示"}
                    name={"roomTypeShow"}
                    rules={[{ required: true, message: "是否显示" }]}
                    style={{ textAlign: 'left' }}
                >
                    <Radio.Group>
                        <Radio value={true}>显示</Radio>
                        <Radio value={false}>不显示</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                    <Button loading={submitLoading} type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}