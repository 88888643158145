import { Button, Col, Divider, Form, Input, message, Radio, Row, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRoomTypeFacilities, useEditRoomTypeFacilities } from "../../services/room-type-facilities-service";
import { useDocumentTitle } from "../../utils"
import  UploadPic  from '../../components/upload-pic';
export const RoomTypeFacilitiesUpdatePage = () => {
    //标题
    useDocumentTitle('编辑房型设施', true)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const roomTypeFacilitiesId = Number(pathname.match(/goods\/room-manage\/room-type-facilities\/update\/(\d+)/)?.[1])

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //表单
    const [form] = useForm();
    const [roomTypeFacilitiesPic, setRoomTypeFacilitiesPic] = useState("");
    const [uploading, setUploading] = useState<boolean>(false);

    //准备回显
    const { data: editingRoomTypeFacilities, isLoading } = useRoomTypeFacilities(roomTypeFacilitiesId);
    console.log("回显",editingRoomTypeFacilities,isLoading);
    useEffect(() => {
        form.setFieldsValue({ ...editingRoomTypeFacilities?.data });
        //封面
        if (editingRoomTypeFacilities?.data?.roomTypeFacilitiesPic) {
            setRoomTypeFacilitiesPic(editingRoomTypeFacilities?.data?.roomTypeFacilitiesPic)
        }
    }, [editingRoomTypeFacilities, form]);

    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useEditRoomTypeFacilities();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingRoomTypeFacilities?.data, ...values, roomTypeFacilitiesPic }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/goods/room-manage/room-type-facilities/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/goods/room-manage/room-type-facilities/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >
               	<Form.Item
                    label={"设施名称"}
                    name={"roomTypeFacilitiesName"}
                    rules={[{ required: true, message: "设施名称必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入设施名称"} />
                </Form.Item>

               	<UploadPic
                    label={'图片'}
                    name={'uploadRoomTypeFacilitiesPic'}
                    pic={roomTypeFacilitiesPic}
                    setPic={setRoomTypeFacilitiesPic}
                    action={'update'}
                    style={{ textAlign: 'left' }}
                >
                </UploadPic>
                <Form.Item
                    label={"是否显示"}
                    name={"roomTypeFacilitiesShow"}
                    rules={[{ required: true, message: "是否显示必选" }]}
                    style={{ textAlign: 'left' }}
                >
                    <Radio.Group>
                        <Radio defaultChecked value={true}>显示</Radio>
                        <Radio value={false}>不显示</Radio>
                    </Radio.Group>
                </Form.Item>
               	<Form.Item
                    label={"排序"}
                    name={"roomTypeFacilitiesSort"}
                    rules={[{ required: true, message: "排序必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入排序"} />
                </Form.Item>

				<Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}