import { Button, Col, Divider, Form, Input, message, Radio, Row, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRoomClass, useEditRoomClass } from "../../services/room-class-service";
import { useDocumentTitle } from "../../utils"
import  UploadPic  from '../../components/upload-pic';
export const RoomClassUpdatePage = () => {
    //标题
    useDocumentTitle('编辑房型分类', true)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const roomClassId = Number(pathname.match(/goods\/room-manage\/room-class\/update\/(\d+)/)?.[1])

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //表单
    const [form] = useForm();
    const [roomClassPic, setRoomClassPic] = useState("");
    const [uploading, setUploading] = useState<boolean>(false);

    //准备回显
    const { data: editingRoomClass, isLoading } = useRoomClass(roomClassId);
    console.log("回显",editingRoomClass,isLoading);
    useEffect(() => {
        form.setFieldsValue({ ...editingRoomClass?.data });
        //封面
        if (editingRoomClass?.data?.roomClassPic) {
            setRoomClassPic(editingRoomClass?.data?.roomClassPic)
        }
    }, [editingRoomClass, form]);

    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useEditRoomClass();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingRoomClass?.data, ...values, roomClassPic }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/goods/room-manage/room-class/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/goods/room-manage/room-class/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >
               	<Form.Item
                    label={"分类名称"}
                    name={"roomClassName"}
                    rules={[{ required: true, message: "分类名称必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入分类名称"} />
                </Form.Item>

               	<Form.Item
                    label={"排序"}
                    name={"roomClassSort"}
                    rules={[{ required: true, message: "排序必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入排序"} />
                </Form.Item>

                <Form.Item
                    label={"是否显示"}
                    name={"roomClassShow"}
                    rules={[{ required: true, message: "是否显示必选" }]}
                    style={{ textAlign: 'left' }}
                >
                    <Radio.Group>
                        <Radio defaultChecked value={true}>显示</Radio>
                        <Radio value={false}>不显示</Radio>
                    </Radio.Group>
                </Form.Item>

				<Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}