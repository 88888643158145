import { Button, Col, Divider, Form, Input, message, Radio, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAddRoomTypeRoomTypeTag } from "../../services/room-type-room-type-tag-service";
import { useDocumentTitle } from "../../utils"
import  UploadPic  from '../../components/upload-pic';
export const RoomTypeRoomTypeTagAddPage = () => {
    //标题
    useDocumentTitle('新建房型与房型标签', true)
    const navigate = useNavigate();

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //表单
    const [form] = useForm();
    const [roomTypeRoomTypeTagPic, setRoomTypeRoomTypeTagPic] = useState("");
    const [uploading, setUploading] = useState<boolean>(false);

    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useAddRoomTypeRoomTypeTag();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values, roomTypeRoomTypeTagPic }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/goods/room-manage/room-type-room-type-tag/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/goods/room-manage/room-type-room-type-tag/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Form
                form={form}
                {...layout}
                onFinish={onFinish}
                initialValues={{
                    roomTypeRoomTypeTagSort: 0,
                    roomTypeRoomTypeTagShow: true
                }}>
               	<Form.Item
                    label={"标签名称"}
                    name={"roomTypeTagName"}
                    rules={[{ required: true, message: "标签名称必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入标签名称"} />
                </Form.Item>

               	<Form.Item
                    label={"标签id"}
                    name={"roomTypeTagId"}
                    rules={[{ required: true, message: "标签id必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入标签id"} />
                </Form.Item>

               	<Form.Item
                    label={"房型id"}
                    name={"roomTypeId"}
                    rules={[{ required: false, message: "房型id必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入房型id"} />
                </Form.Item>

               	<Form.Item
                    label={"商家id"}
                    name={"shopId"}
                    rules={[{ required: true, message: "商家id必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入商家id"} />
                </Form.Item>


               <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                    <Button loading={submitLoading} type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}