import { useMutation, useQuery, useQueryClient } from "react-query";
import { RoomTypeRoomTypeFacilities } from "../interfaces/room-type-room-type-facilities";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useRoomTypeRoomTypeFacilitiesList = (param?: Partial<RoomTypeRoomTypeFacilities>) => {
    const client = useHttp();
    const result = useQuery<any>(['roomTypeRoomTypeFacilitiesPage', param], () => client('roomTypeRoomTypeFacilities', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditRoomTypeRoomTypeFacilities = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<RoomTypeRoomTypeFacilities>) => client(`roomTypeRoomTypeFacilities`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('roomTypeRoomTypeFacilitiesPage')
        }
    )
}

export const useAddRoomTypeRoomTypeFacilities = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<RoomTypeRoomTypeFacilities>) => client(`roomTypeRoomTypeFacilities`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('roomTypeRoomTypeFacilitiesPage')
        }
    )
}

export const useDeleteRoomTypeRoomTypeFacilities = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ roomTypeRoomTypeFacilitiesId }: { roomTypeRoomTypeFacilitiesId: number}) =>
            client(`roomTypeRoomTypeFacilities/${roomTypeRoomTypeFacilitiesId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('roomTypeRoomTypeFacilitiesPage')
        }
    );
};

export const useRoomTypeRoomTypeFacilities = (roomTypeRoomTypeFacilitiesId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['roomTypeRoomTypeFacilities', {roomTypeRoomTypeFacilitiesId}],
        () => client(`roomTypeRoomTypeFacilities/${roomTypeRoomTypeFacilitiesId}`),
        {
            enabled: Boolean(roomTypeRoomTypeFacilitiesId)
        }
    )
}