import { Button, Col, message, Modal, Row, Space, Table, Image, Card, Form, Input } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { ErrorBox } from "../../components/lib"
import { useRoomTypeRoomTypeTagList, useDeleteRoomTypeRoomTypeTag } from "../../services/room-type-room-type-tag-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useRoomTypeRoomTypeTagSearchParams } from "./util"

export const RoomTypeRoomTypeTagListPage = () => {
    useDocumentTitle('房型与房型标签列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const [param, setParam] = useRoomTypeRoomTypeTagSearchParams()
    const {isLoading, error, totalElements, data: list} = useRoomTypeRoomTypeTagList(useDebounce(param, 200))

	//分页
	const paginationProps = {
	  showSizeChanger: true,
	  onShowSizeChange: (current: number, pageSize: number) => {
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		});
	  },
	  defaultCurrent: 1,
	  pageSize: param.pageSize,
	  total: totalElements,
	  onChange: (current: number, pageSize: number) =>
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		}),
	};
    //删除事件
    const { mutateAsync } = useDeleteRoomTypeRoomTypeTag();
    const confirmDeleteRoomTypeRoomTypeTag = (roomTypeRoomTypeTagId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ roomTypeRoomTypeTagId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                          } else {
                            message.error(data.msg)
                          }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/goods/room-manage/room-type-room-type-tag/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
            </Row>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.roomTypeRoomTypeTagId } 
                pagination = { paginationProps }
                scroll={{x:1500}}
            	dataSource={ list || [] }
                columns = {[
                {
                    title: 'ID',
                    dataIndex: 'roomTypeRoomTypeTagId',
                    key: 'roomTypeRoomTypeTagId',
                    align: 'center',
                    width: 100,
                },

                {
                    title: '标签名称',
                    dataIndex: 'roomTypeTagName',
                    key: 'roomTypeTagName',
                    width: 150,
                    align: 'center',
                },

                {
                    title: '标签id',
                    dataIndex: 'roomTypeTagId',
                    key: 'roomTypeTagId',
                    width: 150,
                    align: 'center',
                },

                {
                    title: '房型id',
                    dataIndex: 'roomTypeId',
                    key: 'roomTypeId',
                    width: 150,
                    align: 'center',
                },

                {
                    title: '商家id',
                    dataIndex: 'shopId',
                    key: 'shopId',
                    width: 150,
                    align: 'center',
                },

                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    width: 200,
                    fixed:"right",
                    render: (value, roomTypeRoomTypeTag) => (
                        <Space size="middle">
                            <Link to={"/goods/room-manage/room-type-room-type-tag/update/" + roomTypeRoomTypeTag.roomTypeRoomTypeTagId}>
                                编辑
                            </Link>
                            <a onClick={() => confirmDeleteRoomTypeRoomTypeTag(roomTypeRoomTypeTag.roomTypeRoomTypeTagId)}>
                                删除
                            </a>
                        </Space>
                    )
                }
            ]} />
            <ErrorBox error={ error } />
        </div>
    )
}